import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { gql } from "../../__generated__";
import { useMutation, useQuery } from "@apollo/client";

import PageLoader from "../../components/PageLoader";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CustomButton from "../../components/CutomButton";
import ROUTES from "../../utils/routes";
import PreLoginContainer from "../../containers/PreLogginContainer/PreLoginContainer";
import OtpWrapper from "./OtpWrapper";

const EventJoiningPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showOTP, setOTP] = useState(false);

  // join users to event and redirect to event view page
  const [joinToEvent, { error }] = useMutation(JOIN_TO_EVENT, {
    onError: (err) => {
      graphqlErrorToMsg(err);
    },
    onCompleted: (apiRes) => {
      navigate(ROUTES.EVENT_VIEW_ID + apiRes.joinToBlogUsingLink.blogId);
    },
  });

  // get event Id by sending uuid
  const { data: getEventData, loading: isLoading } = useQuery(GET_EVENT_ID, {
    variables: { eventUuid: params.eventUuid || "" },
  });

  const { loading } = useQuery(ME, {
    onCompleted: (data) => {
      if (data.me.userId && params?.eventUuid) {
        joinToEvent({ variables: { uuid: params.eventUuid } });
      }
    },
  });

  const blogId = getEventData?.blogIdByEventUuid.blogId;

  // const goToEventPage = () => {
  //   if (blogId && params?.eventUuid) {
  //     navigate(
  //       `${ROUTES.EVENT_VIEW_ID}${blogId}?eventUuid=${params.eventUuid}`
  //     );
  //   }
  // };

  const goToRegisterPage = () => {
    const pathname = ROUTES.SIGN_UP;
    const search = `?eventUuid=${params.eventUuid}`;
    navigate(pathname + search);
  };

  const getLoginPageUrl = () => {
    const pathname = ROUTES.LOGINPAGE;
    const search = `?eventUuid=${encodeURIComponent(`${params.eventUuid}`)}`;
    if (!params.eventUuid) return pathname;
    return pathname + search;
  };

  const goToLoginPage = () => {
    const url = getLoginPageUrl();
    navigate(url);
  };

  if (loading || isLoading) return <PageLoader />;

  // if (blogId && params?.eventUuid && !loggedUserInfo?.me) {
  //   const url = getLoginPageUrl();
  //   console.log({ url });

  //   return <Navigate to={url} />;
  // }

  if (showOTP) {
    return (
      <PreLoginContainer width={400}>
        <OtpWrapper eventId={blogId || 0} />
      </PreLoginContainer>
    );
  }

  return (
    <PreLoginContainer width={400}>
      {error ? (
        <Box>{graphqlErrorToMsg(error)}</Box>
      ) : (
        <>
          <Typography>Joining To Event</Typography>
          <Box
            sx={{
              p: 4,
              pb: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <CustomButton
              label="Login"
              loading={isLoading}
              onClick={() => {
                goToLoginPage();
              }}
            />

            <CustomButton
              label="Register"
              loading={isLoading}
              onClick={() => {
                goToRegisterPage();
              }}
            />
            <CustomButton
              label="Continue as Guest"
              loading={isLoading}
              onClick={() => {
                setOTP(true);
              }}
            />
          </Box>
        </>
      )}
    </PreLoginContainer>
  );
};

export default EventJoiningPage;

const ME = gql(`
  query UserDetails {
    me {
      email
      profileCompleted
      userId
      isModerator
      isAdmin
      isCurator
      profile {
        avatarImage
        userName
        createdAt
        dob
        firstName
        gender
        lastName
        updatedAt
        userId
      }
    }
  }`);

const JOIN_TO_EVENT = gql(`
  mutation JoinToBlogUsingLink($uuid:String!){
    joinToBlogUsingLink(uuid:$uuid){
      blogId
    }
  }
  `);

const GET_EVENT_ID = gql(`query blogIdByEventUuid($eventUuid:String!){
    blogIdByEventUuid(eventUuid:$eventUuid){
      blogId
    }
  }`);
