import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Box } from "@mui/material";
import useStyles from "./syles";
import SinglePostComponents from "../SinglePostComponents";
import { THEMES_LIST } from "../../utils/constants";
// import { ViewPostQuery } from "../../__generated__/graphql";
import { UserContext } from "../../Context";
import useWindowSize, { Size } from "../../utils/Hooks/useWindowSize";

interface Props {
  data: any | undefined;
  refetchPost: () => void;
  isImageViewPage?: boolean;
}

export default function PostView(props: Props): JSX.Element {
  const { data, refetchPost, isImageViewPage } = props;
  const postDetails = data?.viewPost;
  const blogDetails = postDetails?.blog;

  const { classes } = useStyles();
  const userContext = useContext(UserContext);

  const loggedUserId = userContext.user?.userId;

  const [joinedDate, setJoinedDate] = useState("");

  React.useEffect(() => {
    if (blogDetails?.createdAt) {
      const date = blogDetails.createdAt;
      const formatDate = moment(date).utc().format("MMMM YYYY");
      setJoinedDate(`Joined ${formatDate}`);
    }
  }, [blogDetails]);

  const theme = blogDetails?.theme;
  const isWallpaperTheme = theme === THEMES_LIST.WALLPAPER;

  const coverImage =
    blogDetails?.themeMediaInfo && blogDetails?.themeMediaInfo?.url;

  const size: Size = useWindowSize();
  if (!postDetails || !blogDetails || !blogDetails.profile)
    return <div> Post not found</div>;

  console.log({ postDetails, test: "test" });

  return (
    <Box
      sx={isWallpaperTheme ? { height: "94vh", overflow: "auto" } : {}}
      className={classes.root}
      style={{
        backgroundSize: "cover",
        backgroundImage:
          isWallpaperTheme && coverImage ? `url(${coverImage})` : "none",
      }}
    >
      <Grid container>
        {!isWallpaperTheme && coverImage && (
          <Grid item xs={12}>
            <Box
              className={classes.cover}
              style={{
                backgroundSize: "cover",
                backgroundImage: coverImage ? `url(${coverImage})` : "none",
                backgroundColor: coverImage ? "initial" : "#bbbaba3b",
                height: size?.width ? size.width / 3 : "45vh",
                minHeight: 150,
              }}
            ></Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={isWallpaperTheme && coverImage ? classes.overlay : ""}
        >
          <SinglePostComponents
            id={postDetails.id}
            isImageViewPage={!!isImageViewPage}
            userDetails={
              blogDetails.default ? blogDetails.profile : postDetails.profile
            }
            postDetails={postDetails}
            isPersonalPage={blogDetails.default}
            pageOwner={
              (loggedUserId === blogDetails?.profile?.userId ||
                loggedUserId === postDetails?.userId) &&
              !postDetails?.isShared
            }
            isWallpaperTheme={isWallpaperTheme}
            // blog details
            blogDetails={blogDetails}
            joinedDate={joinedDate}
            refetchPost={refetchPost}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
