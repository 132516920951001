import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import useStyles from "./styles";
import "./styles.css";

import ROUTES from "../../utils/routes";
import EventCardAction from "./EventCardAction";
import { FaFolder } from "react-icons/fa6";
import { UserContext } from "../../Context";

interface Props {
  eventId: number;
  postId?: number;
  title: string;
  highlighted: boolean;
  approved?: boolean;
  imageUrl: string;
  isEventImage?: boolean;
}

export default function EventCard(props: Props): JSX.Element {
  const {
    highlighted,
    eventId,
    imageUrl,
    title,
    isEventImage,
    postId,
    approved,
  } = props;

  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [showBotttomPart, setShowBottomPart] = useState(false);

  // context

  const tabIndex = 0;

  const renderImage = () => {
    const url = imageUrl;
    return (
      <img
        // eslint-disable-next-line react/destructuring-assignment
        src={url || ""}
        alt={title || ""}
        onError={() => setImageLoaded(false)}
        onLoad={() => setImageLoaded(true)}
        className={!imageLoaded ? classes.loadingImage : classes.image}
      />
    );
  };

  function routeToIndividualPost(): void {
    if (isEventImage && postId) {
      navigate(`${ROUTES.IMAGE_VIEW_ID}${postId}`);
    } else {
      navigate(`${ROUTES.EVENT_VIEW_ID}${eventId}`);
    }
  }

  function handleClick(): void {
    setShowBottomPart(!showBotttomPart);
  }

  const isCurator = !!user?.isCurator;
  return (
    <Box
      className={
        highlighted ? classes.highlightContainer : classes.postCardContainer
      }
    >
      <Box
        className={classes.postCardImageContainer}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={tabIndex}
      >
        {/* {imageLoaded ? ( */}
        <div
          className={classes.imageWrapper}
          onClick={() => routeToIndividualPost()}
          role="button"
          onKeyDown={routeToIndividualPost}
          tabIndex={tabIndex}
        >
          {renderImage()}

          {title && (
            <Box>
              <div
                style={{
                  justifyContent: "flex-start",
                }}
                className={classes.eventOverlay}
              >
                <Typography variant="h6" className={classes.eventtitle}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {!isEventImage && <FaFolder size={30} color="#8ECAE6" />}
                    <Box className={classes.title}>{title}</Box>
                  </Box>
                </Typography>
              </div>
            </Box>
          )}
          {isCurator && isEventImage && postId && (
            <EventCardAction
              eventId={eventId}
              postId={postId}
              highlighted={highlighted}
              approved={!!approved}
            />
          )}
        </div>
      </Box>
    </Box>
  );
}
