import { useState } from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import useStyles from "./styles";
import "./styles.css";

import { FaRegCheckSquare } from "react-icons/fa";

interface Props {
  refetch: () => void;
  imageUrl: string;
  isSelected: boolean;
  handleSelect: () => void;
}

export default function DownloadCard(props: Props): JSX.Element {
  const { imageUrl, isSelected, handleSelect } = props;

  const { classes } = useStyles();

  const [imageLoaded, setImageLoaded] = useState(false);

  const [showBotttomPart, setShowBottomPart] = useState(false);

  const tabIndex = 0;

  const renderImage = () => {
    const url = imageUrl;
    return (
      <img
        // eslint-disable-next-line react/destructuring-assignment
        src={url || ""}
        onError={() => setImageLoaded(false)}
        onLoad={() => setImageLoaded(true)}
        className={!imageLoaded ? classes.loadingImage : classes.image}
        onClick={() => handleSelect()}
      />
    );
  };

  function handleClick(): void {
    setShowBottomPart(!showBotttomPart);
  }

  return (
    <Box className={classes.postCardContainer}>
      <Box
        className={classes.postCardImageContainer}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={tabIndex}
      >
        <div className={classes.imageWrapper}>
          {renderImage()}
          {isSelected && (
            <Box onClick={handleSelect}>
              <motion.div
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
                className={classes.overlay}
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <FaRegCheckSquare size={25} />
              </motion.div>
            </Box>
          )}
        </div>
      </Box>
    </Box>
  );
}
