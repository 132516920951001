import { CircularProgress, Container } from "@mui/material";

type PageLoaderProps = {
  minHeight?: number;
};
export default function PageLoader({ minHeight }: PageLoaderProps) {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: minHeight ? minHeight : "20em",
      }}
    >
      <CircularProgress />
    </Container>
  );
}
