import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, Grid, Typography, colors } from "@mui/material";
import { BlogTypes } from "../../__generated__/graphql";
import MobCard from "./MobCard";
import CustomButton from "../CutomButton";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../PageLoader";
import { gql } from "../../__generated__";
import FilterTabs from "./FilterTabs";
import { UserContext } from "../../Context";
import fetchMoreData, { checkHasMore } from "../../utils/fetchMore";
import { BLOGS_MEMBER_JOINING_TYPE } from "../../utils/constants";

const PER_PAGE = 20;

type Props = {
  openCreateMob: () => void;
  title: string;
  blogType: BlogTypes;
  isMobList?: boolean;
};

const Title = ({
  title,
  isEvent,
  openCreateMob,
}: {
  title: string;
  isEvent: boolean;
  openCreateMob: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Typography fontSize={24} fontWeight={600}>
        {title}
      </Typography>
      <CustomButton
        label={isEvent ? "Create event" : "Create mob"}
        onClick={() => openCreateMob()}
      />
    </Box>
  );
};

export default function MobsList({
  openCreateMob,
  title,
  blogType,
  isMobList,
}: Props) {
  const [tabValue, setTabValue] = useState("own");

  const { user } = useContext(UserContext);

  const isEventsPage = blogType === BlogTypes.Events;

  const [
    getMobs,
    { data: mobsData, loading: isMobsLoading, refetch, fetchMore },
  ] = useLazyQuery(MOBS_SEARCH, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const searchInfo = {
      blogType,
      ownMobs: false,
      ownEvents: false,
      memberEvents: false,
    };
    if (blogType === BlogTypes.Mobs) {
      searchInfo.ownMobs = tabValue !== "all";
    } else if (isEventsPage) {
      searchInfo.ownEvents = tabValue === "own";
      searchInfo.memberEvents = tabValue === "all";
    }
    getMobs({
      variables: {
        searchInfo,
        pagination: { perPage: PER_PAGE, nextPage: 1 },
      },
    });
  }, [tabValue, getMobs, blogType, isEventsPage]);

  const hasMore = checkHasMore(mobsData, "loggedUserBlogsSearch");

  return (
    <Box>
      <Title
        title={title}
        openCreateMob={openCreateMob}
        isEvent={isEventsPage}
      />
      {(blogType === BlogTypes.Mobs || blogType === BlogTypes.Events) && (
        <FilterTabs tabValue={tabValue} setTabValue={setTabValue} />
      )}
      {isMobsLoading && <PageLoader />}
      {!isMobsLoading &&
        mobsData?.loggedUserBlogsSearch?.data?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 4,
              width: "100%",
              fontSize: 20,
              background: colors.grey[200],
              mt: 4,
              borderRadius: 2,
            }}
          >
            {title} Not found
          </Box>
        )}
      <Grid container spacing={3}>
        {!isMobsLoading &&
          mobsData?.loggedUserBlogsSearch?.data?.map((mob) => {
            const isByBeingAdded =
              mob?.membersJoiningType ===
              BLOGS_MEMBER_JOINING_TYPE.BY_BEING_ADDED;

            return (
              <Grid item xs={12} xl={6} key={mob?.id}>
                <MobCard
                  isMobCard={isMobList}
                  isByBeingAdded={isByBeingAdded}
                  id={mob?.id}
                  image={mob?.themeMediaInfo?.url}
                  mobName={mob?.name}
                  isOwner={mob?.userId === user?.userId}
                  members={mob?.members?.map((m) => m?.userId || 0) || []}
                  currentPrivacy={mob?.privacyLevelInfo.id}
                  currentPermission={mob?.memberJoiningTypeInfo?.id}
                  refetchMobs={refetch}
                  isEventsPage={isEventsPage}
                />
              </Grid>
            );
          })}
        {hasMore && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          >
            <Button
              onClick={() => {
                fetchMoreData({
                  dataSet: mobsData,
                  query: "loggedUserBlogsSearch",
                  variables: {
                    searchInfo: { ownMobs: tabValue !== "all", blogType },
                  },
                  perPage: PER_PAGE,
                  fetchMore,
                });
              }}
              sx={{
                borderRadius: 5,
                fontSize: 12,
                color: "text.secondary",
              }}
            >
              Load more...
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const MOBS_SEARCH = gql(`
    query LoggedUserBlogsSearch($searchInfo:LoggedUserBlogsSearchInput!,$pagination:Pagination){
      loggedUserBlogsSearch( searchInfo:$searchInfo,pagination:$pagination){
        paginationDetails{
          currentPage
          lastPage
          totalRecords
        }
        data{
          id
          userId
          blogType
          name
          themeMediaInfo{
            blogId
            themeId
            url
          }
          privacyLevelInfo{
            id
            name
          }
          membersJoiningType
          memberJoiningTypeInfo{
            id
            name
          }
          members{
            userId
            profile{
              userId
              firstName
              lastName
            }
          }
        }
      }
    }
`);
