import { useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import CustomButton from "../CutomButton";
import ReactModal from "../ReactModal";
import { gql } from "../../__generated__";
import GenarateQR from "./generateQR";
import PageLoader from "../PageLoader";
import DropDown from "./dropDown";
import MemberModal from "./memberModal";
import PostDeleteModal from "../SinglePostComponents/PostDeleteModal";
import ROUTES from "../../utils/routes";
import CreateEventPage from "../../pages/CreateEvent";

type Props = {
  handleUpload: () => void;
  eventId: number;
};

export default function HeaderSection({ eventId, handleUpload }: Props) {
  const [showModal, setModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const eventUuid = searchParams.get("eventUuid");

  const { data: qrCodeData } = useQuery(QR_CODE, {
    variables: {
      blogId: eventId,
    },
  });

  const {
    data: eventInfo,
    loading,
    refetch,
  } = useQuery(EVENT_BASIC_INFO, {
    variables: {
      blogId: eventId,
      eventUuid: eventUuid || null,
    },
  });

  if (loading) return <PageLoader />;

  if (eventInfo?.blogBasicInfoById) {
    const eventData = eventInfo.blogBasicInfoById;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.8 }}>
            <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
              {eventData.name}
            </Typography>
            <Typography>{eventData.description}</Typography>
            <div>
              {/* {eventData.profile?.firstName && (
                <Typography>{`@${eventData.profile?.firstName}`}</Typography>
              )} */}
              <Typography sx={{ color: "rgb(33, 158, 188)" }} fontSize={14}>
                {moment(eventData.createdAt).format("MMMM DD YYYY  hh:mm:ss a")}
              </Typography>
            </div>
            <Box sx={{ my: 1 }}>
              <CustomButton
                label="Upload Images"
                onClick={() => handleUpload()}
              />
            </Box>
          </Box>
          {eventData.isEventOwner && (
            <div>
              <DropDown
                eventId={eventId}
                setMemberModal={() => setMemberModal(true)}
                setModal={() => setModal(true)}
                setQrModal={() => setQrModal(true)}
                hasQrCode={!!qrCodeData?.inviteQrCodeInfo.qrCode}
                setDeleteModal={() => setDeleteModal(true)}
              />
            </div>
          )}
        </Box>

        <ReactModal
          handleClose={() => setModal(false)}
          isOpen={showModal}
          modelWidth={{ xs: "90%", md: "80%", lg: "60%" }}
        >
          <CreateEventPage
            eventId={eventId}
            handleCancel={() => setModal(false)}
            handleComplete={() => {
              refetch();
              setModal(false);
            }}
          />
        </ReactModal>
        {qrModal && (
          <GenarateQR
            joinUrl={qrCodeData?.inviteQrCodeInfo?.joinUrl || ""}
            openModal={qrModal}
            handleClose={() => setQrModal(false)}
            eventId={eventId}
            imageUrl={qrCodeData?.inviteQrCodeInfo.qrCode || ""}
          />
        )}
        {memberModal && (
          <MemberModal
            isOpen={memberModal}
            blogId={eventId}
            handleClose={() => {
              setMemberModal(false);
            }}
          />
        )}

        {deleteModal && (
          <PostDeleteModal
            isMob
            id={eventId}
            isOpen={deleteModal}
            handleClose={() => setDeleteModal(false)}
            handleComplete={() => navigate(ROUTES.EVENTS)}
            modalContent="Your event will be deleted and cannot be retrieved again. Are you sure you want to delete your event?"
          />
        )}
      </>
    );
  }
  return <Box sx={{ mt: 6, p: 5, fontSize: 24 }}>Event details not found</Box>;
}

const QR_CODE = gql(`query InviteQrCodeInfo($blogId:Int!){
  inviteQrCodeInfo(blogId:$blogId){
    userId
    blogId
    uuid
    qrCode
    joinUrl
    createdAt
  }
}`);

const EVENT_BASIC_INFO =
  gql(`query BlogBasicInfoById($blogId:Int!,$eventUuid:String){
    blogBasicInfoById(blogId:$blogId,eventUuid:$eventUuid){
      id
      name
      description
      profile{
        userId
        firstName
      }
      isEventOwner
      createdAt
    }
  }`);
