import { useState } from "react";
import { useMutation } from "@apollo/client";
import { StarBorderRounded, StarRounded } from "@mui/icons-material";
import { Button, CircularProgress, SvgIcon } from "@mui/material";
import { toast } from "react-toastify";

import useStyles from "./styles";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { gql } from "../../__generated__";
import { BsFillPlayBtnFill, BsPlayBtn } from "react-icons/bs";

type PostHighlightButtonProps = {
  highlighted: boolean;
  postId: number;
  eventId: number;
  isEventCard?: boolean;
};

export default function PostHighlightButton({
  highlighted,
  postId,
  eventId,
  isEventCard,
}: PostHighlightButtonProps) {
  const [isHighlighted, setHighlight] = useState(highlighted);
  const { classes } = useStyles();

  const [highlightPost, { loading: ishighlighting }] = useMutation(
    HIGHLIGHT_POST,
    {
      onError: (err) => {
        toast.error(graphqlErrorToMsg(err));
        setHighlight((s) => !s);
      },
      onCompleted: () => {},
    }
  );
  const [removeHighlightPost, { loading: isRemoving }] = useMutation(
    REMOVE_HIGHLIGHT_POST,
    {
      onError: (err) => {
        toast.error(graphqlErrorToMsg(err));
        setHighlight((s) => !s);
      },
      onCompleted: () => {},
    }
  );

  const handleHighlighted = () => {
    if (highlighted) {
      removeHighlightPost({ variables: { postId } });
    } else {
      highlightPost({ variables: { info: { blogId: eventId, postId } } });
    }
    setHighlight((s) => !s);
  };
  if (isHighlighted) {
    return (
      <Button
        onClick={() => handleHighlighted()}
        variant="contained"
        className={classes.actionActiveButtons}
        color="warning"
      >
        {isRemoving ? (
          <CircularProgress size={24} />
        ) : (
          <SvgIcon>
            {isEventCard ? <BsFillPlayBtnFill /> : <StarRounded />}
          </SvgIcon>
        )}
      </Button>
    );
  }

  return (
    <Button
      onClick={() => handleHighlighted()}
      aria-label="Highlight"
      variant="contained"
      className={classes.actionButtons}
      color="warning"
    >
      {ishighlighting ? (
        <CircularProgress size={24} />
      ) : (
        <SvgIcon>{isEventCard ? <BsPlayBtn /> : <StarBorderRounded />}</SvgIcon>
      )}
    </Button>
  );
}

const HIGHLIGHT_POST = gql(`mutation HighlightPost($info:PostHighlightInput!){
  highlightPost(info:$info){
    message
  }
}`);

const REMOVE_HIGHLIGHT_POST = gql(`mutation RemoveHighlight($postId:Int!){
  removeHighlight(postId:$postId){
    message
  }
}`);
