import { useState } from "react";
import { FaRegCheckSquare } from "react-icons/fa";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { Button, CircularProgress, SvgIcon } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import useStyles from "./EventCardActionsStyles";

type EventApproveButtonProps = {
  approved: boolean;
  postId: number;
};

export default function EventApproveButton({
  approved,
  postId,
}: EventApproveButtonProps) {
  const [isApproved, setApproved] = useState(approved);
  const { classes } = useStyles();
  const [approvePost, { loading: isApproving }] = useMutation(APPROVE_POST, {
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
      setApproved((s) => !s);
    },
    onCompleted: () => {},
  });

  const [removeApprovalFromPost, { loading: isRemoving }] = useMutation(
    REMOVE_APPROVE_POST,
    {
      onError: (err) => {
        toast.error(graphqlErrorToMsg(err));
        setApproved((s) => !s);
      },
      onCompleted: () => {},
    }
  );

  const handleApproved = () => {
    if (approved) {
      removeApprovalFromPost({ variables: { postId } });
    } else {
      approvePost({ variables: { postId } });
    }
    setApproved((s) => !s);
  };

  if (isApproved) {
    return (
      <Button
        onClick={() => handleApproved()}
        variant="contained"
        className={classes.actionActiveButtons}
        color="success"
      >
        {isRemoving ? (
          <CircularProgress size={24} />
        ) : (
          <SvgIcon>
            <FaRegCheckSquare />
          </SvgIcon>
        )}
      </Button>
    );
  }
  return (
    <Button
      onClick={() => handleApproved()}
      variant="contained"
      className={classes.actionButtons}
      color="success"
    >
      {isApproving ? (
        <CircularProgress size={24} />
      ) : (
        <SvgIcon>
          <MdCheckBoxOutlineBlank />
        </SvgIcon>
      )}
    </Button>
  );
}

const APPROVE_POST = gql(`mutation ApprovetPost($postId:Int!){
  approvePost(postId:$postId){
    message
  }
}`);

const REMOVE_APPROVE_POST = gql(`mutation RemoveApprovalFromPost($postId:Int!){
  removeApprovalFromPost(postId:$postId){
    message
  }
}`);
