import { Box, Container } from "@mui/material";

import useStyles from "./styles";
import { gql } from "../../__generated__";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PostForm from "../../components/PostForm";
import { useEffect } from "react";
import { BlogTypes } from "../../__generated__/graphql";

type Props = {
  blogId?: number;
  onComplete?: () => void;
  isPersonalBlogPost?: boolean;
  handleCancel?: () => void;
  isEventPage?: boolean;
};
export default function CreatePostPage({
  blogId: id,
  onComplete,
  isPersonalBlogPost,
  handleCancel,
  isEventPage,
}: Props): JSX.Element {
  // hooks
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  const { data } = useQuery(SELF_BLOG);

  const [getPermission, { data: permissionData }] =
    useLazyQuery(BLOG_PERMISSION);
  const [createPost, { loading }] = useMutation(CREATE_POST, {
    onCompleted: () => {
      if (id) {
        onComplete && onComplete();
      } else {
        window.location.replace("/dashboard");
      }
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  useEffect(() => {
    if (id) {
      getPermission({ variables: { blogId: id } });
    }
  }, [getPermission, id]);

  type CreatePostTypes = {
    title: string;
    text: string;
    file: File | null;
    allowReshare: boolean;
    privacyLevel: number;
  };

  function submitCreatePost(values: CreatePostTypes) {
    const blogId = id || data?.selfBlogs[0]?.id || 1;
    const variables = {
      info: {
        title: values.title,
        description: values.text,
        allowReshare: values.allowReshare,
        blogId,
        hidden: false,
        privacyLevel: values.privacyLevel,
      },
      file: values.file,
    };
    createPost({ variables });
  }

  return (
    <Box className={s.container}>
      <Container maxWidth="md">
        <Box className={s.root}>
          <PostForm
            isOnlyMe={
              permissionData?.loggedUserBlogPermission.blogType ===
              BlogTypes.Logs
            }
            handleSubmit={submitCreatePost}
            loading={loading}
            isPersonalBlogPost={!!isPersonalBlogPost || !id}
            handleCancel={() => {
              if (isEventPage && handleCancel) {
                handleCancel();
              } else {
                navigate(-1);
              }
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

const CREATE_POST = gql(`
  mutation CreatePost($info: CreatePostInputs!, $file: Upload!) {
    createPost(info: $info, file: $file) {
      id
    }
  }`);

const SELF_BLOG = gql(`
query SelfBlogs {
  selfBlogs {
    id
  }
}
  `);

const BLOG_PERMISSION = gql(`query BlogPermission($blogId:Int!){
    loggedUserBlogPermission(blogId:$blogId){
      blogType
      alreadyMember
      isOwner
      blogPrivacyLevel
    }
  }`);
