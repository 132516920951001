import { ReactElement } from "react";
import BackgroundScreen from "../../components/BackGroundScreen";
import useStyles from "./styles";

type PreLoginContainerProps = {
  children: ReactElement;
  width?: number;
};

export default function PreLoginContainer({
  children,
  width,
}: PreLoginContainerProps) {
  const { classes: s } = useStyles();
  return (
    <BackgroundScreen>
      <div className={s.formContainer}>
        <div
          className={s.formWrapper}
          style={width ? { width: `${width}px` } : {}}
        >
          <p className={s.title}>Moblog</p>
          <>{children}</>
        </div>
      </div>
    </BackgroundScreen>
  );
}
