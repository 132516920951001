import { Box } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";

import ReactHooksInput from "../ReactHooksInput";
import CustomButton from "../CutomButton";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { toast } from "react-toastify";
import { LOCAL_KEY } from "../../utils/constants";
import ROUTES from "../../utils/routes";
const schema = yup
  .object()
  .shape({
    otp: yup.string().required(),
  })
  .required();

type ValidateOtpProps = {
  handleComplete: () => void;
};

export default function LoginWithOtp({ handleComplete }: ValidateOtpProps) {
  type FormData = yup.InferType<typeof schema>;

  const [loginWithOtp] = useMutation(LOGIN_WITH_OTP, {
    onCompleted: (data) => {
      localStorage.setItem(LOCAL_KEY.ACCESS_TOKEN, data.loginWithOtp.token);
      if (data.loginWithOtp.otpReference) {
        window.location.replace(`/join/${data.loginWithOtp.otpReference}`);
      } else {
        window.location.replace(ROUTES.DASHBOARD);
      }
      handleComplete();
    },
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
    mode: "all",
  });
  const handleValidateOTP = (data: FormData) => {
    loginWithOtp({ variables: { otp: data.otp } });
  };
  return (
    <form onSubmit={handleSubmit(handleValidateOTP)}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "70%" }}>
          <ReactHooksInput
            label="Code"
            name="otp"
            required
            placeholder="Enter 6 digit code"
            register={register}
            // error={errors.email?.message}
            touched={touchedFields.otp}
          />
        </Box>
        <Box sx={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
          <CustomButton
            disabled={!!errors.email?.message}
            label="Send"
            onClick={() => {}}
            sx={{ mb: 2 }}
            type="submit"
          />
        </Box>
      </Box>
      <Box sx={{ mb: 2 }} />
    </form>
  );
}

const LOGIN_WITH_OTP = gql(`
  mutation LoginWithOtp($otp: String!) {
    loginWithOtp(otp: $otp) {
      token
      otpReference
    }
  }
`);
