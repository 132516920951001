import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import EventForm from "../../components/EventForm";
import ROUTES from "../../utils/routes";

type CreateEventPageProps = {
  eventId?: number | null;
  handleComplete?: () => void;
  handleCancel?: () => void;
};

export default function CreateEventPage({
  eventId,
  handleComplete,
  handleCancel,
}: CreateEventPageProps) {
  // hooks
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  const [createEvent, { loading: isCreating }] = useMutation(CREATE_EVENT, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      toast.success("Event create successfully");
      navigate(ROUTES.EVENTS);
    },
  });

  const [updateEvent, { loading: isUpdating }] = useMutation(UPDATE_EVENT, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      toast.success("Event updated successfully");
      if (handleComplete) {
        handleComplete();
      }
    },
  });

  const handleCreateEvent = (values: any) => {
    createEvent({
      variables: {
        info: {
          blogName: values.title,
          blogAbout: values.description,
        },
        blogImage: values.file,
      },
    });
  };
  const handleUpdateEvent = (values: any) => {
    if (eventId) {
      updateEvent({
        variables: {
          info: {
            blogId: eventId,
            name: values.title,
            about: values.description,
          },
          blogImage: values.file,
        },
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box className={s.root}>
        <Typography fontSize={24} fontWeight={600} textAlign={"center"}>
          {eventId ? "Update Event" : "Create a Event"}
        </Typography>

        {/* forms starts */}
        <EventForm
          eventId={eventId || 0}
          handleCancel={() => {
            if (handleCancel) {
              handleCancel();
            } else {
              navigate(-1);
            }
          }}
          onSubmit={(values) => {
            if (eventId) {
              handleUpdateEvent(values);
            } else {
              handleCreateEvent(values);
            }
          }}
          isSubmitting={isCreating || isUpdating}
        />
      </Box>
    </Container>
  );
}

const CREATE_EVENT = gql(`
mutation CreateEvent($info: CreateEventInput!, $blogImage: Upload){
  createEvent(info: $info,blogImage: $blogImage) {
    message
  }
}
`);

const UPDATE_EVENT =
  gql(`mutation UpdateEvent($info: UpdateEventInput!, $blogImage: Upload) {
  updateEvent(info: $info, blogImage: $blogImage) {
    message
  }
}`);
