import { Box, Typography } from "@mui/material";

type MessageBoxProps = {
  message: string;
};

export default function MessageBox({ message }: MessageBoxProps) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "rgba(33, 158, 188, 0.15)",
        borderRadius: 2,
        p: 6,
        maxWidth: "lg",
        margin: "auto",
      }}
    >
      <Typography
        fontSize={{ xs: 16, md: 18, lg: 24 }}
        textAlign={"center"}
        color={"text.primary"}
      >
        {message}
      </Typography>
    </Box>
  );
}
