import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { gql } from "../../__generated__";
import ReactModal from "../ReactModal";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../PageLoader";
import { Box, Typography } from "@mui/material";
import CustomButton from "../CutomButton";
import CopyClipboard from "../FriendSearch/CopyClipboard";

type GenarateQRProps = {
  openModal: boolean;
  handleClose: () => void;
  eventId: number;
  imageUrl?: string;
  joinUrl?: string;
};

export default function GenarateQR({
  openModal = false,
  handleClose,
  eventId,
  imageUrl = "",
  joinUrl = "",
}: GenarateQRProps) {
  const [url, setUrl] = useState(imageUrl);
  const [joinLink, setJoinLink] = useState(joinUrl);
  const [generateQR, { loading }] = useMutation(GENERATE_QR_CODE, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: (data) => {
      toast.success("QR generated successfully");
      setUrl(data.generateQrCode.url);
      setJoinLink(data.generateQrCode.joinUrl || "");
    },
  });

  useEffect(() => {
    if (!imageUrl) {
      generateQR({ variables: { blogId: eventId } });
    }
  }, []);

  return (
    <div>
      <ReactModal
        handleClose={() => handleClose()}
        isOpen={openModal}
        modelWidth={{ xs: "70%", sm: "50%", md: "40%", lg: "30%" }}
      >
        <Typography fontSize={24} fontWeight={600} textAlign={"center"}>
          QR code
        </Typography>
        {loading && <PageLoader />}
        {url && (
          <Box sx={{ p: 2 }}>
            <img
              src={url}
              alt="QR"
              style={{
                width: "100%",
                maxWidth: "100%",
                backgroundSize: "cover",
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ px: 1 }}>Joining Link</Typography>
          {joinLink && <CopyClipboard uuid={joinLink} />}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
          <CustomButton
            sx={{ width: 150 }}
            label="Close"
            onClick={() => handleClose()}
            variant="outlined"
          />
          <CustomButton
            sx={{ width: 150 }}
            label={"Download QR"}
            disabled={!url}
            onClick={() => {
              window.open(url, "_blank");
            }}
          />
        </Box>
      </ReactModal>
    </div>
  );
}

const GENERATE_QR_CODE = gql(`mutation GenerateQrCode($blogId:Int!){
  generateQrCode(blogId:$blogId){
    filename
    url
    joinUrl
  }
}`);
