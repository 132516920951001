import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Button, Card, Grid, SvgIcon, Tab, Tabs } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import HooksInput from "../Input/Input";
import * as yup from "yup";

type EventSearchProps = {
  handleSearch: (fields: searchFields) => void;
};

const schema = yup
  .object()
  .shape({
    title: yup.string(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type searchFields = {
  title: string | null;
  flagged: boolean | null;
  highlighted: boolean | null;
  privacyLevel: number | null;
};

export const EventSearch = ({ handleSearch }: EventSearchProps) => {
  const { register, handleSubmit, control, getValues } = useForm<FormData>({
    defaultValues: {
      title: "",
      filter: "all",
      privacyLevel: null,
    },
  });

  return (
    <Card sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"filter"}
              render={({ field: { onChange, value } }) => (
                <>
                  <Tabs
                    value={value}
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: string
                    ) => {
                      onChange(newValue);
                      handleSearch({
                        title: getValues("title"),
                        flagged: newValue === "flagged",
                        highlighted: newValue === "highlighted",
                        privacyLevel: getValues("privacyLevel"),
                      });
                    }}
                  >
                    <Tab value={"all"} label="All" />
                    {/* <Tab value={"flagged"} label="Approved" />
                    <Tab value={"highlighted"} label="Highlighted " /> */}
                    {/* <Tab value="three" label="Highlighted" /> */}
                  </Tabs>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <HooksInput
              register={register}
              error={null}
              touched={false}
              label="Event Title"
              name="title"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                display: "flex",
                height: "54px",
                mb: 2,
                width: "100%",
              }}
              startIcon={
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              }
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
