import { Navigate, Outlet, useLocation } from "react-router-dom";
import ROUTES from "./routes";
import { useQuery } from "@apollo/client";
import { gql } from "../__generated__";

export default function ProtectedRoute() {
  const { data } = useQuery(ME, { fetchPolicy: "cache-only" });
  const location = useLocation();

  const loggedUser = data?.me.userId;
  const isCompletedUser = data?.me.profileCompleted;

  if (loggedUser && isCompletedUser) return <Outlet />;
  if (loggedUser)
    return (
      <Navigate
        to={{
          pathname: ROUTES.COMPLETE_SIGN_IN,
        }}
      />
    );
  localStorage.clear();
  return (
    <Navigate
      to={{
        pathname: "/",
        search: `?from=${encodeURIComponent(location.pathname)}`,
      }}
    />
  );
}

const ME = gql(`
  query UserDetails {
    me {
      email
      profileCompleted
      userId
      isModerator
      isAdmin
      isCurator
      profile {
        avatarImage
        userName
        createdAt
        dob
        firstName
        gender
        lastName
        updatedAt
        userId
      }
    }
  }`);
