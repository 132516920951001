import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const mb = "25px";
const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    background: theme.palette.background.default,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
    paddingTop: "30px",
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    minHeight: "97vh",
    paddingBottom: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: mb,
    color: theme.palette.text.primary,
  },
  privacy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "600px",
    width: "100%",
    marginBottom: mb,
  },
  section: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
      maxHeight: "85vh",
    },
  },
  createBtn: {
    backgrond: theme.palette.text.primary,
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    borderRadius: "25px",
    padding: "10px 40px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },
  deleteBtn: {
    position: "absolute",
    right: "10px",
    top: "25px",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: "5px",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.text.primary}`,
    cursor: "pointer",
    "&:hover": {
      scale: "1.01",
    },
  },
  imageWrapper: {
    height: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxHeight: "250px",
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    backgroundSize: "cover",
    borderRadius: "10px",
  },
  error: {
    color: "red !important",
    padding: "0 2px",
    fontSize: 14,
  },
}));

export default useStyles;
