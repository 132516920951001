import { useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../components/PageLoader";
import PostEditPage from "../adminComponents/Post/PostEdit";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../utils/graphqlErrorToMsg";
import PostView from "../adminComponents/Post/PostView";
import PostDeleteModal from "../components/SinglePostComponents/PostDeleteModal";
import { BlogTypes } from "../__generated__/graphql";
import { EventSearch } from "../adminComponents/Events/EventSearch";
import { EventsTable } from "../adminComponents/Events/EventsTable";

const AdminEventPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [postEditId, setPostId] = useState<null | number>(null);
  const [postViewId, setPostViewId] = useState<null | number>(null);
  const [postDeleteId, setPostDeleteId] = useState<null | number>(null);

  const {
    data: postList,
    loading,
    refetch,
  } = useQuery(ADMIN_EVENT_SEARCH, {
    notifyOnNetworkStatusChange: true,
    variables: {
      searchInfo: { blogName: "", blogType: BlogTypes.Events },
      pagination: { nextPage: page, perPage: rowsPerPage },
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const handlePageChange = (event: any, value: number) => {
    setPage(value + 1);
    refetch({
      pagination: { nextPage: value + 1, perPage: rowsPerPage },
    });
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
    refetch({
      pagination: { nextPage: 1, perPage: event.target.value },
    });
  };

  if (postEditId) {
    return (
      <>
        <PostEditPage
          handleClose={() => setPostId(null)}
          postId={postEditId}
          refetchPostList={() => refetch()}
        />
      </>
    );
  }
  if (postViewId) {
    return (
      <PostView postViewId={postViewId} goBack={() => setPostViewId(null)} />
    );
  }

  console.log("testing");

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Events List</Typography>
              </Stack>
            </Stack>
            <EventSearch
              handleSearch={(search) =>
                refetch({
                  searchInfo: {
                    blogName: search.title || "",
                    blogType: BlogTypes.Events,
                  },
                  pagination: {
                    nextPage: 1,
                    perPage: rowsPerPage,
                  },
                })
              }
            />
            {loading && <PageLoader />}
            {postList?.adminBlogsSearch && (
              <EventsTable
                count={
                  postList.adminBlogsSearch.paginationDetails?.totalRecords
                }
                items={postList.adminBlogsSearch.data}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={postList.adminBlogsSearch.paginationDetails?.currentPage}
                rowsPerPage={rowsPerPage}
                setEditId={setPostId}
                refetchEvents={() => {
                  refetch();
                }}
                setPostViewId={setPostViewId}
                setPostDeleteId={setPostDeleteId}
              />
            )}
          </Stack>
          {postDeleteId && (
            <PostDeleteModal
              id={postDeleteId}
              isOpen={!!postDeleteId}
              handleClose={() => setPostDeleteId(null)}
              handleComplete={() => {
                setPostDeleteId(null);
                refetch();
              }}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default AdminEventPage;

const ADMIN_EVENT_SEARCH = gql(`
  query AdminEventSearch($searchInfo: AdminBlogsSearchInput!, $pagination: Pagination) {
  adminBlogsSearch(searchInfo: $searchInfo, pagination: $pagination) {
    data {
      id
      name
      title
      userId
      themeMediaInfo{
        blogId
        themeId
        url
      }
      media {
        blogId
        fileUrl
      }
      profile{
        userId
        firstName
        lastName
      }
      description
      about
    }
    paginationDetails {
      currentPage
      lastPage
      totalRecords
    }
  }
}
`);
