import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import { Delete, LinkSharp } from "@mui/icons-material";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { MODAL_LIST } from "../../utils/constants";

type CardMenuProps = {
  anchorEl: Element | null;
  open: boolean;
  isMobCard?: boolean;
  isByBeingAdded: boolean;
  isEvent?: boolean;
  handleClose: () => void;
  openModal: (key: string) => void;
};

export default function CardMenu({
  anchorEl,
  open,
  isMobCard,
  handleClose,
  openModal,
  isByBeingAdded,
  isEvent,
}: CardMenuProps) {
  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            openModal(isEvent ? MODAL_LIST.editEvent : MODAL_LIST.edit);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        {isMobCard && (
          <MenuItem
            onClick={() => {
              openModal(MODAL_LIST.member);
              handleClose();
            }}
          >
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Manage Members
          </MenuItem>
        )}
        {isByBeingAdded && (
          <MenuItem
            onClick={() => {
              openModal(MODAL_LIST.genarate);
              handleClose();
            }}
          >
            <ListItemIcon>
              <LinkSharp fontSize="small" />
            </ListItemIcon>
            Manage invitation link
          </MenuItem>
        )}
        {!isEvent && (
          <>
            <MenuItem
              onClick={() => {
                openModal(MODAL_LIST.privacy);
              }}
            >
              <ListItemIcon>
                <LockOpenOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Manage Privacy
            </MenuItem>
            <MenuItem
              onClick={() => {
                openModal(MODAL_LIST.permission);
              }}
            >
              <ListItemIcon>
                <SecurityOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Manage Permission
            </MenuItem>
          </>
        )}
        <MenuItem onClick={() => openModal(MODAL_LIST.delete)}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
