import { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";

import useStyles from "./styles";
import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { BlogTypes } from "../../__generated__/graphql";
import PageLoader from "../../components/PageLoader";
import Search from "../../components/Search";
import fetchMoreData, { checkHasMore } from "../../utils/fetchMore";
import LoadMore from "../../components/LoadMore";
import MessageBox from "../../components/MessageBox";
import MasonryContainer from "../../containers/MasonryContainer";
import EventCard from "../../components/EventCard/EventCard";

const PER_PAGE = 50;

export default function EventsPage() {
  const { classes } = useStyles();
  const [searchInfo, setSearchInfo] = useState({
    ownMobs: false,
    blogType: BlogTypes.Events,
    name: "",
  });

  const [getEvents, { loading, data, fetchMore }] = useLazyQuery(EVENT_SEARCH, {
    variables: {
      searchInfo,
      pagination: { perPage: PER_PAGE, nextPage: 1 },
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    fetchPolicy: "network-only",
  });

  const fetchData = () => {
    fetchMoreData({
      dataSet: data,
      query: "loggedUserBlogsSearch",
      variables: {
        searchInfo,
      },
      perPage: PER_PAGE,
      fetchMore,
    });
  };

  useEffect(() => {
    getEvents({
      variables: {
        searchInfo,
        pagination: { perPage: PER_PAGE, nextPage: 1 },
      },
    });
  }, [searchInfo, getEvents]);

  if (!loading && !data)
    return (
      <Box className={classes.info}>
        Page did not load properly.
        <br /> Please refresh the page.
      </Box>
    );
  const hasMore = checkHasMore(data, "loggedUserBlogsSearch");

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div className={classes.mainDashboardContainer}>
        <div className={classes.dahsboard}>
          <Box>
            <Search
              isEventSearch
              handleSubmit={(name) => {
                setSearchInfo((s) => ({ ...s, name }));
              }}
            />
          </Box>
          {loading && <PageLoader />}
          {!loading && (
            <Box>
              <Box sx={{ flexGrow: 1, my: 6 }}>
                {data?.loggedUserBlogsSearch?.data?.length !== 0 && (
                  <MasonryContainer>
                    {data?.loggedUserBlogsSearch?.data?.map((item) => {
                      if (!item) return null;
                      return (
                        <EventCard
                          key={item?.id}
                          eventId={item.id}
                          imageUrl={item?.themeMediaInfo?.url || ""}
                          title={item?.name || ""}
                          highlighted={false}
                        />
                      );
                    })}
                  </MasonryContainer>
                )}

                {hasMore && <LoadMore fetchData={fetchData} />}

                {data?.loggedUserBlogsSearch?.data?.length === 0 && (
                  <MessageBox message={"Events not found"} />
                )}
              </Box>
            </Box>
          )}
        </div>
      </div>
    </Container>
  );
}

const EVENT_SEARCH = gql(`
    query EventSearch($searchInfo:LoggedUserBlogsSearchInput!,$pagination:Pagination){
      loggedUserBlogsSearch( searchInfo:$searchInfo,pagination:$pagination){
        paginationDetails{
          currentPage
          lastPage
          totalRecords
        }
        data{
          id
          userId
          blogType
          name
          themeMediaInfo{
            blogId
            themeId
            url
          }
        }
      }
    }
`);
