import { Box, Container, Typography } from "@mui/material";
import useStyles from "./styles";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import MobForm from "../../components/MobFrom";

type CreateMobsPageProps = {
  goBack: () => void;
  mobId?: number | null;
  handleComplete?: () => void;
};

export default function CreateMobsPage({ goBack, mobId }: CreateMobsPageProps) {
  // hooks
  const { classes: s } = useStyles();

  const [createMob, { loading: isCreating }] = useMutation(CREATE_MOB, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      toast.success("Mob create successfully");
      goBack();
    },
  });

  const createNewMob = (values: any) => {
    createMob({
      variables: {
        info: {
          blogName: values.title,
          blogAbout: values.description,
          theme: values.theme,
          privacyLevel: values.privacy,
          memberJoiningType: values.permissions,
          friends: values.friends,
        },
        blogImage: values.file,
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Box className={s.root}>
        <Typography fontSize={24} fontWeight={600} textAlign={"center"}>
          {mobId ? "Update Mob" : "Create a Mob"}
        </Typography>

        {/* forms starts */}
        <MobForm
          goBack={() => goBack()}
          onSubmit={(values) => createNewMob(values)}
          isSubmitting={isCreating}
        />
      </Box>
    </Container>
  );
}

const CREATE_MOB = gql(`
mutation CreateNewBlogByUser($info:CreateBlogIput!,$blogImage:Upload){
    createNewBlogByUser(info:$info,blogImage:$blogImage){
      message
    }
  }
`);
