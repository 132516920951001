import { Box, Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CustomButton from "../CutomButton";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../utils/routes";

type FloatingFooter = {
  isDownloading?: boolean;
  download: () => void;
  count: number;
  eventId: number;
};

// Create a makeStyles hook to define custom styles
const useStyles = makeStyles()((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    zIndex: 1300, // Ensures the footer is above other content
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const FloatingFooter = ({
  isDownloading,
  download,
  count,
  eventId,
}: FloatingFooter) => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const disabled = count === 0 || count > 100;

  return (
    <Box className={classes.footer}>
      <Container className={classes.buttonContainer}>
        <CustomButton
          variant="outlined"
          label="Back"
          onClick={() => {
            navigate(`${ROUTES.EVENT_VIEW_ID}${eventId}`);
          }}
        />
        <CustomButton
          loading={isDownloading}
          label={count ? `Download (${count})` : "Download"}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              download();
            }
          }}
        />
      </Container>
    </Box>
  );
};

export default FloatingFooter;
