import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { primary } = theme.palette;
  const mainColor = primary.main;

  return {
    actionButtons: {
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
      opacity: 0.65,
    },
    actionActiveButtons: {
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
    },
    checkBox: {
      color: mainColor,
    },
  };
});

export default useStyles;
