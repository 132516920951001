import { useEffect, useState } from "react";
import { User } from "../../Context";

const useUserData = (data: any, LOCAL_KEY: any) => {
  const [adminApp, setAdminApp] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_KEY.ACCESS_TOKEN);
    const isAdminUI = localStorage.getItem(LOCAL_KEY.ADMIN_DASHBOARD);

    if (!token) {
      setAdminApp(false);
    } else if (data) {
      const userData = {
        userId: data.me.userId,
        email: data.me.email,
        userName: data.me.profile.userName || "",
        avatarImage: data.me.profile.avatarImage,
        isAdmin: data.me.isAdmin,
        isModerator: data.me.isModerator,
        isCompleted: data.me.profileCompleted,
        isCurator: !!data.me.isCurator,
      };
      setUser(userData);

      const isAdminLogged = data?.me.isAdmin || data?.me.isModerator;

      if (isAdminLogged && isAdminUI === "false") {
        setAdminApp(false);
      } else if (isAdminLogged) {
        setAdminApp(true);
        localStorage.setItem(LOCAL_KEY.ADMIN_DASHBOARD, "true");
      } else {
        setAdminApp(false);
        localStorage.removeItem(LOCAL_KEY.ADMIN_DASHBOARD);
      }
    } else {
      setAdminApp(false);
    }
  }, [data, LOCAL_KEY]);

  return { user, adminApp, setUser };
};

export default useUserData;
