import FriendSearch from "../FriendSearch";
import PostDeleteModal from "../SinglePostComponents/PostDeleteModal";
import PrivacyModal from "./PrivacyModal";
import PermissionModal from "./PermissionModal";
import LeaveModal from "./LeaveModal";
import { MODAL_LIST } from "../../utils/constants";
import { Box } from "@mui/material";
import ReactModal from "../ReactModal";
import MobThemeWrapper from "../MobThemeWrapper";
import CreateEventPage from "../../pages/CreateEvent";

type Props = {
  allModals: {
    memberModal: boolean;
    deleteModal: boolean;
    privacyModal: boolean;
    permissionModal: boolean;
    leaveModal: boolean;
    genarateLinkModal: boolean;
    editModal: boolean;
    editEventModal: boolean;
  };
  toggleModals: (key: string, value: boolean) => void;
  id: number;
  userId: number | null;
  currentPrivacy: number;
  currentPermission: number;
  refetchMobs: () => void;
  memberList: [] | number[];
  setMemberCount: (friends: [] | number[]) => void;
  isAdminPanel?: boolean;
};

export default function ModalWrapper({
  id,
  userId,
  toggleModals,
  currentPrivacy,
  currentPermission,
  refetchMobs,
  memberList,
  setMemberCount,
  allModals,
  isAdminPanel,
}: Props) {
  return (
    <div>
      {allModals.editModal && (
        <Box>
          <ReactModal
            title="Edit Mob"
            isOpen={allModals.editModal}
            handleClose={() => toggleModals(MODAL_LIST.edit, false)}
            modelWidth={{ xs: "90%", md: "60%", lg: "50%" }}
          >
            <MobThemeWrapper mobId={id} />
          </ReactModal>
        </Box>
      )}
      {allModals.memberModal && (
        <FriendSearch
          mobId={id}
          userId={userId}
          isUpdate
          isLinkGenarate={false}
          currentFriends={memberList}
          handleClose={() => toggleModals(MODAL_LIST.member, false)}
          onSave={(friends) => {
            setMemberCount(friends);
          }}
        />
      )}
      {allModals.deleteModal && (
        <PostDeleteModal
          id={id}
          isOpen={allModals.deleteModal}
          isMob
          modalContent={
            "Your mob will be deleted and cannot be retrieved again. Are you sure you want to delete your mob?"
          }
          handleClose={() => toggleModals(MODAL_LIST.delete, false)}
          handleComplete={() => {
            toggleModals(MODAL_LIST.delete, false);
            refetchMobs();
          }}
        />
      )}

      {/* privacy modal */}
      {allModals.privacyModal && (
        <PrivacyModal
          mobId={id}
          isOpen={allModals.privacyModal}
          handleClose={() => toggleModals(MODAL_LIST.privacy, false)}
          currentPrivacy={currentPrivacy}
          refetchMobs={refetchMobs}
        />
      )}

      {/* Permission modal */}
      {allModals.permissionModal && (
        <PermissionModal
          mobId={id}
          isOpen={allModals.permissionModal}
          handleClose={() => toggleModals(MODAL_LIST.permission, false)}
          currentPermission={currentPermission}
          refetchMobs={refetchMobs}
        />
      )}

      {/* leave modal */}
      {allModals.leaveModal && (
        <LeaveModal
          isOpen={allModals.leaveModal}
          handleClose={() => toggleModals(MODAL_LIST.leave, false)}
          mobId={id}
          refetchMobs={refetchMobs}
        />
      )}

      {allModals.genarateLinkModal && (
        <FriendSearch
          userId={userId || null}
          mobId={id}
          isLinkGenarate
          currentFriends={[]}
          handleClose={() => toggleModals(MODAL_LIST.genarate, false)}
          onSave={(friends) => {
            setMemberCount(friends);
          }}
        />
      )}
      {allModals.editEventModal && (
        <ReactModal
          isOpen={true}
          handleClose={() => toggleModals(MODAL_LIST.editEvent, false)}
          modelWidth={{ xs: "95%", sm: "80%", lg: "60%" }}
        >
          <CreateEventPage
            eventId={id}
            handleCancel={() => {
              toggleModals(MODAL_LIST.editEvent, false);
            }}
            handleComplete={() => {
              if (isAdminPanel) {
                refetchMobs();
              }
              toggleModals(MODAL_LIST.editEvent, false);
            }}
          />
        </ReactModal>
      )}
    </div>
  );
}
