import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import CustomInput from "../CustomInput";

import useStyles from "./styles";
import { POSTS_PRIVACY_LEVELS } from "../../utils/constants";
import CustomButton from "../CutomButton";
import FileUploader from "../FileUploader";
import { EventFormTypes, getPostEditDetails } from "../../utils/postHelper";
import { BlogTypes } from "../../__generated__/graphql";
import { gql } from "../../__generated__";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../PageLoader";
import DndMultipleUpload from "../DndUpload/multipleUpload";

const initialValues = {
  title: "",
  text: "",
  allowReshare: true,
  file: null,
  privacyLevel: POSTS_PRIVACY_LEVELS.PUBLIC,
};

interface EventFormProps {
  postId?: number;
  isOnlyMe?: boolean;
  handleSubmit: (v: EventFormTypes) => void;
  loading: boolean;
  isUpdate?: boolean;
  isPersonalBlogPost: boolean;
  renderAdminButtons?: () => JSX.Element;
  renderPostDelete?: () => JSX.Element;
  handleCancel: () => void;
}

export default function EventPhotoUploadForm({
  handleSubmit,
  loading,
  isUpdate,
  postId,
  handleCancel,
  renderAdminButtons,
  renderPostDelete,
}: EventFormProps) {
  const { classes: s } = useStyles();

  // fetch post data
  const [getPostData, { data: postData, loading: isLoading }] = useLazyQuery(
    POST_VIEW,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (postId) {
      getPostData({ variables: { postId } });
    }
  }, [getPostData, postId]);

  const postDetails = getPostEditDetails(postData?.viewPost);

  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().label("title"),
    text: Yup.string().nullable().label("Text"),
    file: isUpdate
      ? Yup.array()
          .of(Yup.mixed())
          .max(10, "Cannot upload more than 10 files")
          .nullable()
      : Yup.array().of(Yup.mixed()).max(10, "Cannot upload more than 10 files"),
  });

  const isLogPost = postData?.viewPost?.blog?.blogTypeInfo === BlogTypes.Logs;

  const defaultPrivacy = isLogPost
    ? POSTS_PRIVACY_LEVELS.ONLY_ME
    : POSTS_PRIVACY_LEVELS.PUBLIC;

  const handleImageChange = (
    images: File[] | [],
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const files = Array.from(images);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newPreviews]);
    setFieldValue("file", files);
  };

  // Remove an image by index
  const handleRemoveImage = (
    index: number,
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);

    const newImages = [...values.file];
    newImages.splice(index, 1);
    setFieldValue("file", newImages);
  };

  if (isLoading) return <PageLoader />;

  return (
    <Formik
      initialValues={
        postDetails
          ? {
              title: postDetails.title,
              text: postDetails.description,
              allowReshare: postDetails.allowReshare || false,
              file: [] as File[],
              privacyLevel: postDetails.privacyLevel || defaultPrivacy,
            }
          : initialValues
      }
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        handleSubmit: submitForm,
        setFieldValue,
        handleChange,
        handleBlur,
        touched,
      }) => {
        return (
          <Box sx={{ borderRadius: 2, p: { xs: 2, sm: 3 } }}>
            <Form className={s.form}>
              {/* input section */}
              <section className={s.section}>
                <Typography variant="h5" className={s.title}>
                  {isUpdate ? "Post Edit" : "Upload new images"}
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {imagePreviews.length === 0 && (
                      <DndMultipleUpload
                        height={300}
                        onChange={(file: File[]) => {
                          handleImageChange(file, setFieldValue);
                        }}
                      />
                    )}
                    {imagePreviews.length > 0 && (
                      <Grid container spacing={2}>
                        {imagePreviews.map((p, index) => (
                          <Grid
                            item
                            xs={4}
                            md={3}
                            className={s.imageWrapper}
                            sx={{ position: "relative" }}
                            key={Math.random()}
                          >
                            {renderAdminButtons && renderAdminButtons()}
                            {!isUpdate && (
                              <div className={s.deleteBtn}>
                                <RiDeleteBin5Fill
                                  size={25}
                                  onClick={() => {
                                    handleRemoveImage(
                                      index,
                                      values,
                                      setFieldValue
                                    );
                                  }}
                                />
                              </div>
                            )}
                            <img src={p} alt="blog" className={s.image} />
                            {renderPostDelete && renderPostDelete()}
                          </Grid>
                        ))}
                        {errors?.file && (
                          <Grid item xs={12} className={s.error}>
                            {String(errors.file)}
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {imagePreviews && isUpdate && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <FileUploader
                          onChange={(file: File | null) => {
                            if (file) {
                              handleImageChange([file], setFieldValue);
                            }
                          }}
                          component={
                            <CustomButton
                              onClick={() => {}}
                              label="Update Image"
                            />
                          }
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CustomInput
                          label={"Title"}
                          name="title"
                          placeholder="Title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.title}
                          touched={touched.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInput
                          label={"Description"}
                          name="text"
                          placeholder="Description"
                          inputType="textarea"
                          value={values.text}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors?.text}
                          touched={touched.text}
                        />
                        {/* <TextEditor /> */}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* buttons sections */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <CustomButton
                        variant="outlined"
                        sx={{
                          mr: 2,
                        }}
                        onClick={() => {
                          handleCancel();
                        }}
                        label={"Cancel"}
                      />

                      <CustomButton
                        loading={loading}
                        sx={{
                          bgcolor: "text.primary",
                          color: "text.secondary",
                        }}
                        disabled={loading}
                        onClick={() => {
                          submitForm();
                        }}
                        label={isUpdate ? "Update" : "Upload"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </section>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}

const POST_VIEW = gql(`query GetPostInfo($postId:Int!){
  viewPost(postId:$postId){
    id
    userId
    blogId
    title
    description
    privacyLevel
    hidden
    isShared
    shareText
    flagged
    highlighted{
      postId
    }
    originalPost{
      id
      userId
      blogId
      title
      description
      privacyLevel
      isShared
      media{
        id
        postId
        fileUrl
      }
    }
    allowReshare
    createdAt
    updatedAt
    sharedUserProfile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    profile{
      userName
      userId
      firstName
      lastName
      createdAt
    }
    blog{
      id
      userId
      blogType
      blogTypeInfo
    }
    media{
      id
      postId
      fileUrl
    }
  }
}`);
