import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql } from "../__generated__";

export default function ProtectedAdminRoutes() {
  const { data } = useQuery(ME, { fetchPolicy: "cache-only" });
  const loggedUser = data?.me;

  if (loggedUser && loggedUser?.isAdmin) return <Outlet />;
  if (!loggedUser) return <Navigate to="/admin/dashboard" />;

  localStorage.clear();
  return <Navigate to="/" />;
}

export function ProtectedModeratorRoutes() {
  const { data } = useQuery(ME, { fetchPolicy: "cache-only" });
  const loggedUser = data?.me;

  if ((loggedUser && loggedUser?.isModerator) || loggedUser?.isAdmin)
    return <Outlet />;
  return <Navigate to="/" />;
}

const ME = gql(`
  query UserDetails {
    me {
      email
      profileCompleted
      userId
      isModerator
      isAdmin
      isCurator
      profile {
        avatarImage
        userName
        createdAt
        dob
        firstName
        gender
        lastName
        updatedAt
        userId
      }
    }
  }`);
