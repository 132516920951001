import Masonry from "react-masonry-css";
import { useState } from "react";
import PageLoader from "../../components/PageLoader";
import { gql } from "../../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { toast } from "react-toastify";

import useStyles from "./styles";
import { Box, Container, Typography } from "@mui/material";
import { PostSearchPageEnum } from "../../__generated__/graphql";
import DownloadCard from "../../components/PostCardContainer/downloadCard";
import fetchMoreData, { checkHasMore } from "../../utils/fetchMore";
import LoadMore from "../../components/LoadMore";
import { useParams } from "react-router-dom";
import FloatingFooter from "../../components/FloatingFooter";
import ReactModal from "../../components/ReactModal";
import { BsCheckCircle } from "react-icons/bs";
import CustomButton from "../../components/CutomButton";

const PER_PAGE = 50;
const breakpointColumnsObj = {
  default: 8,
  1200: 6,
  900: 4,
  500: 2,
};
export default function EventDownloadPage() {
  const params = useParams();
  const eventId = Number(params.eventId) || 0;

  const { classes: s } = useStyles();
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [openModal, setModal] = useState(false);

  const { loading, data, fetchMore } = useQuery(EVENT_IMAGES_LIST, {
    fetchPolicy: "network-only",
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    variables: {
      blogId: eventId,
      pagination: {
        perPage: PER_PAGE,
        nextPage: 1,
      },
    },
  });

  const [download, { loading: isDownloading }] = useMutation(DOWNLOAD_IMAGES, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      setModal(true);
    },
  });

  const toggleSelectImage = (imageId: string) => {
    console.log({ imageId });

    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageId)) {
        return prevSelectedImages.filter((img) => img !== imageId);
      } else {
        return [...prevSelectedImages, imageId];
      }
    });
  };

  const fetchData = () => {
    fetchMoreData({
      dataSet: data,
      query: "mobPostsByBlogId",
      variables: {
        searchInfo: { page: PostSearchPageEnum.Latest },
      },
      perPage: PER_PAGE,
      fetchMore,
    });
  };

  const hasMore = checkHasMore(data, "mobPostsByBlogId");

  if (loading) return <PageLoader />;

  if (data?.mobPostsByBlogId?.data) {
    return (
      <>
        <Container
          maxWidth="xl"
          sx={{ my: 3, color: `rgb(2, 48, 71)`, mb: 10 }}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName={`my-masonry-grid_column ${s.gridColumn}`}
          >
            {data?.mobPostsByBlogId?.data.map((item) => {
              if (!item) return null;
              const key = item.media?.[0]?.s3Key || "";
              return (
                <DownloadCard
                  key={item?.id}
                  imageUrl={item?.media?.[0]?.fileUrl || ""}
                  refetch={() => fetchData()}
                  isSelected={selectedImages.includes(key)}
                  handleSelect={() => toggleSelectImage(key)}
                />
              );
            })}
          </Masonry>
          {hasMore && <LoadMore fetchData={fetchData} />}
        </Container>
        <FloatingFooter
          eventId={eventId}
          count={selectedImages.length}
          isDownloading={isDownloading}
          download={() => {
            download({
              variables: { s3Keys: selectedImages.filter((x) => !!x) },
            });
          }}
        />
        <ReactModal
          isOpen={openModal}
          handleClose={() => {
            setModal(false);
          }}
          modelWidth={{ xs: "350px", md: "450px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BsCheckCircle size={60} />
            <Typography sx={{ textAlign: "center" }}>
              Please check your email to download the zip file
            </Typography>
            <CustomButton
              onClick={() => setModal(false)}
              label="Close"
              sx={{ mt: 2 }}
            />
          </Box>
        </ReactModal>
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 6, my: 6 }}>
      <Typography color={"text.primary"} fontSize={24}>
        Event images not found
      </Typography>
    </Box>
  );
}

const EVENT_IMAGES_LIST =
  gql(`query EventImageById($blogId:Int!,$pagination:Pagination){
    mobPostsByBlogId(blogId:$blogId,pagination:$pagination){
       paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        id
        userId
        blogId
        title
        description
        privacyLevel
        hidden
        allowReshare
        isShared
        approved
        highlighted{
          postId
        }
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        media{
          id
          postId
          fileUrl
          s3Key
        }
        createdAt
        updatedAt
      }
      
    }
  }`);

const DOWNLOAD_IMAGES = gql(`
    mutation DownloadEventImages($s3Keys:[String]!){
        downloadBlogImages(s3Keys:$s3Keys){
          message
        }
      }
    `);
