import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    actionButtonsWrapper: {
      position: "absolute",
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
      bottom: 5,
      right: 5,
    },
    actionButtons: {
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
      opacity: 0.65,
    },
    actionActiveButtons: {
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
    },
  };
});

export default useStyles;
