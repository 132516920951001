import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import {
  AiOutlinePlusSquare,
  AiOutlineSearch,
  AiOutlineSetting,
} from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import useTheme from "./styles";
import { SearchContext } from "../../Context";
import ROUTES from "../../utils/routes";

interface DesktopViewProps {
  pages: string[];
  handleOpenSettingsMenu: (event: React.MouseEvent<HTMLElement>) => void;
  userDetails: any;
}
export default function DesktopView({
  pages,
  handleOpenSettingsMenu,
  userDetails,
}: DesktopViewProps): JSX.Element {
  // hooks
  const navigate = useNavigate();
  const searchContext = useContext(SearchContext);

  const { classes: s } = useTheme();
  const { search } = useLocation();

  function getQuery(): any {
    const params = new URLSearchParams(search);
    return params;
  }

  return (
    <Box sx={{ width: "100%", display: { xs: "none", lg: "flex" } }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={5}>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                justifyContent: "left",
              },
            }}
          >
            {pages.map((page: string) => {
              const q = getQuery();
              const isActive = q.get("query") === page.toLowerCase();

              return (
                <Box
                  key={page}
                  color="text.primary"
                  className={isActive ? s.activeLink : s.link}
                  onClick={() => {
                    if (page === "Events") {
                      navigate(ROUTES.EVENTS);
                    } else {
                      navigate(`/dashboard?query=${page.toLowerCase()}`);
                    }
                  }}
                >
                  {page}
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h6"
            noWrap
            color="text.primary"
            className={s.logo}
            sx={{
              width: "100%",
              textAlign: "center",
            }}
            onClick={() => navigate("/dashboard")}
          >
            Moblog
          </Typography>
        </Grid>

        <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex", alignItems: "center" },
            }}
          >
            <IconButton
              size="large"
              color="primary"
              onClick={() =>
                searchContext?.setShowSearch(!searchContext.showSearch)
              }
              className={s.iconButton}
            >
              <AiOutlineSearch />
            </IconButton>
            <IconButton
              size="large"
              color="primary"
              onClick={() => navigate(`/user/${userDetails.userId}`)}
              className={s.iconButton}
            >
              <FaRegUserCircle color="primary.main" size={25} />
            </IconButton>
            <IconButton
              size="large"
              color="primary"
              className={s.iconButton}
              onClick={() => navigate("/create-post")}
            >
              <AiOutlinePlusSquare />
            </IconButton>
            <IconButton
              size="large"
              color="primary"
              className={s.iconButton}
              onClick={handleOpenSettingsMenu}
            >
              <AiOutlineSetting />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
