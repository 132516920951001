import { useState } from "react";
import { Box, Button, CircularProgress, Grid, SvgIcon } from "@mui/material";
import { FlagOutlined, FlagRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { useMutation } from "@apollo/client";
import { gql } from "../../__generated__";
import useStyles from "./styles";
import PostHighlightButton from "../../components/PostHighlightButton";
import EventApproveButton from "../../components/EventCard/EventApproveButton";

type AdminCardActionsProps = {
  refetch: () => void;
  postId: number;
  blogId: number;
  highlighted: boolean;
  flagged: boolean;
  isEventPost?: boolean;
};

export default function AdminCardActions({
  refetch,
  postId,
  blogId,
  highlighted,
  flagged,
  isEventPost,
}: AdminCardActionsProps) {
  // states
  const [postFlagged, setPostFlagged] = useState(flagged);

  // mutations

  const [flagPost, { loading: isFlagging }] = useMutation(FLAG_POST, {
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    onCompleted: () => {
      setPostFlagged(true);
      refetch();
    },
  });
  const [removeFlaggedPost, { loading: isRemovingFlag }] = useMutation(
    REMOVE_FLAG_POST,
    {
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
      onCompleted: () => {
        setPostFlagged(false);
        refetch();
      },
    }
  );

  //   functions

  function flagThePost(): void {
    flagPost({ variables: { postId } });
  }

  function unFlagThePost(): void {
    removeFlaggedPost({ variables: { postId } });
  }

  const { classes: s } = useStyles();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 5,
        left: 5,
        display: "flex",
        gap: 1,
        px: 1,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {isEventPost && (
        <Grid style={{ paddingTop: 10 }}>
          <EventApproveButton approved={false} postId={postId} />
        </Grid>
      )}
      <Grid style={{ paddingTop: 10 }}>
        <PostHighlightButton
          highlighted={highlighted}
          postId={postId}
          eventId={blogId}
          isEventCard={!!isEventPost}
        />
      </Grid>
      {!isEventPost && (
        <Grid style={{ paddingTop: 10 }}>
          {postFlagged ? (
            <Button
              onClick={() => unFlagThePost()}
              variant="contained"
              className={s.actionActiveButtons}
              color="info"
            >
              {isRemovingFlag ? (
                <CircularProgress size={24} />
              ) : (
                <FlagRounded />
              )}
            </Button>
          ) : (
            <Button
              onClick={() => flagThePost()}
              variant="contained"
              className={s.actionButtons}
              color="info"
            >
              {isFlagging ? (
                <CircularProgress size={24} />
              ) : (
                <SvgIcon>
                  <FlagOutlined />
                </SvgIcon>
              )}
            </Button>
          )}
        </Grid>
      )}
    </Box>
  );
}

const FLAG_POST = gql(`mutation FlagPost($postId:Int!){
    flagPost(postId:$postId){
      message
    }
  }`);

const REMOVE_FLAG_POST = gql(`mutation RemoveFlaggedPost($postId:Int!){
    removeFlaggedPost(postId:$postId){
      message
    }
  }`);
