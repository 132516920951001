import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridColumn: {
    paddingLeft: 15,
    backgroundClip: "padding-box",
  },
}));

export default useStyles;
