import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "initial",
    },
  },
}));

export default useStyles;
