import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppBar, Box, ThemeProvider } from "@mui/material";
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { useQuery } from "@apollo/client";

import { lightTheme, darkTheme } from "./utils/themes";
import { SearchContext, UserContext } from "./Context";
import Search from "./components/Search";
import NavigationBar from "./components/NavigationBar";
import AppRoutes from "./AppRoutes";
import { gql } from "./__generated__";

import "react-toastify/dist/ReactToastify.css";
import AdminRoutes from "./AdminRoutes";
import { createTheme } from "./utils/adminTheme";
import { LOCAL_KEY } from "./utils/constants";
import "simplebar-react/dist/simplebar.min.css";
import PageLoader from "./components/PageLoader";
import useUserData from "./utils/Hooks/useUserData";

function App() {
  const [mode, changeMode] = useState<string>("light");
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const theme = mode === "light" ? lightTheme : darkTheme;
  const adminTheme = createTheme();

  const { data, loading } = useQuery(ME);
  const { user, setUser, adminApp } = useUserData(data, LOCAL_KEY);

  const hideNavBar = data?.me.profileCompleted;

  if (loading) return null;

  if (adminApp === null) return <PageLoader />;

  // ADMIN PANEL
  if (adminApp) {
    return (
      <div className="App">
        <ThemeProvider theme={adminTheme}>
          <UserContext.Provider
            value={{
              setUser,
              user,
            }}
          >
            <ToastContainer theme="colored" />
            <BrowserRouter>
              <AdminRoutes />
            </BrowserRouter>
          </UserContext.Provider>
        </ThemeProvider>
      </div>
    );
  }

  // FE USER VIEW
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UserContext.Provider
          value={{
            setUser,
            user,
          }}
        >
          <SearchContext.Provider value={{ showSearch, setShowSearch }}>
            <ToastContainer theme="colored" />
            <BrowserRouter>
              {hideNavBar && (
                <>
                  <NavigationBar changeMode={changeMode} mode={mode} />
                  <AppBar />
                </>
              )}
              {showSearch && (
                <>
                  <Box sx={{ minHeight: 30 }} />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Search />
                  </motion.div>
                </>
              )}
              <AppRoutes />
            </BrowserRouter>
          </SearchContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;

const ME = gql(`
  query UserDetails {
    me {
      email
      profileCompleted
      userId
      isModerator
      isAdmin
      isCurator
      profile {
        avatarImage
        userName
        createdAt
        dob
        firstName
        gender
        lastName
        updatedAt
        userId
      }
    }
  }`);
