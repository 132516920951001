import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import * as yup from "yup";
import DndUpload from "../DndUpload";
import CustomButton from "../CutomButton";
import ReactHooksInput from "../ReactHooksInput";
import { THEMES_LIST } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlogMemberJoinType, BlogPrivacy } from "../../__generated__/graphql";
import FileUploader from "../FileUploader";
import { useLazyQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import PageLoader from "../PageLoader";

const schema = yup
  .object()
  .shape({
    file: yup.mixed().nullable(),
    title: yup.string().required().label("Title"),
    description: yup.string().label("Description"),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

type EventFormProps = {
  isSubmitting?: boolean;
  blogImage?: string | null;
  defaultValues?: any;
  onSubmit: (values: FormData) => void;
  handleCancel: () => void;
  eventId?: number;
};

export default function EventForm({
  defaultValues,
  handleCancel,
  onSubmit,
  isSubmitting,
  blogImage,
  eventId,
}: EventFormProps) {
  const [getEventInfo, { loading }] = useLazyQuery(EVENT_INFO, {
    fetchPolicy: "network-only",
  });

  const [preview, setPreview] = useState<any>(blogImage || null);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {
      file: null,
      title: "",
      description: "",
      theme: THEMES_LIST.WALLPAPER,
      privacy: BlogPrivacy.Public,
      permissions: BlogMemberJoinType.Any,
      friends: [],
    },
    mode: "all",
  });

  useEffect(() => {
    if (eventId) {
      getEventInfo({
        variables: {
          blogId: eventId,
        },
        onCompleted(data) {
          setPreview(data.blogInfoById.themeMediaInfo?.url);
          reset({
            file: null,
            title: data.blogInfoById.name || "",
            description: data.blogInfoById.description || "",
          });
        },
      });
    }
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ py: 5 }}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {!preview && (
            <DndUpload
              hasError={!!errors?.file?.message}
              accept="image/*"
              height={250}
              onChange={(file: File | null) => {
                if (file) {
                  const objectUrl = URL.createObjectURL(file);
                  setPreview(objectUrl);
                } else {
                  setPreview(null);
                }
                setValue("file", file);
              }}
            />
          )}

          {preview && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mt: 2,
                gap: 2,
              }}
            >
              <img
                src={preview}
                alt="blog"
                style={{ maxWidth: "100%", borderRadius: 10 }}
              />
              <FileUploader
                onChange={(file: File | null) => {
                  if (file) {
                    const objectUrl = URL.createObjectURL(file);
                    setPreview(objectUrl);
                    setValue("file", file);
                  } else {
                    setPreview(null);
                  }
                }}
                component={
                  <CustomButton onClick={() => {}} label="Update Image" />
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <ReactHooksInput
            label="Title"
            name="title"
            required
            placeholder="Title"
            register={register}
            error={errors.title?.message}
            touched={touchedFields.title}
          />
        </Grid>
        <Grid item xs={12}>
          <ReactHooksInput
            label="Description"
            name="description"
            inputType="textarea"
            required
            placeholder="Description"
            register={register}
            error={errors.description?.message}
            touched={touchedFields.description}
            autoFocus
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          gap={4}
        >
          <CustomButton
            label="Cancel"
            onClick={() => handleCancel()}
            variant="outlined"
          />
          {isSubmitting ? (
            <CustomButton label="Loading..." onClick={() => {}} />
          ) : (
            <CustomButton
              label={eventId ? "Update" : "Create"}
              type="submit"
              onClick={() => {}}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}

const EVENT_INFO = gql(`
  query EventInfo($blogId:Int!){
    blogInfoById(blogId:$blogId){
      id
      userId
      name
      description
      about
      themeMediaInfo{
        blogId
        themeId
        url
      }
      createdAt
    }
  }
  `);
