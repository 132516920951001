import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { Scrollbar } from "../scrollbar";
import { getPostTitle } from "../../utils/postHelper";
import { GET_ADMIN_ROUTES } from "../../utils/adminRoutes";
import { useState } from "react";
import CardMenuWrapper from "../../components/MobsList/CardMenuWrapper";

export const EventsTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    refetchEvents,
  } = props;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobId, setMobId] = useState(null);
  const [addedMembers, setAddedMembers] = useState<number[] | []>([]);
  const [allModals, toggleModals] = useState({
    editModal: false,
    memberModal: false,
    deleteModal: false,
    privacyModal: false,
    permissionModal: false,
    leaveModal: false,
    genarateLinkModal: false,
    editEventModal: false,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getUserId = () => {
    if (!mobId) return null;
    const userId = items.find((x: any) => x.id === mobId)?.profile.userId;
    return userId;
  };

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((post: any) => {
                const isSelected = selected.includes(post.id);
                const createdAt = moment(post.createdAt).format(
                  "YYYY/MM/DD hh:mm A"
                );

                const imageUrl = post.themeMediaInfo?.url || "";

                return (
                  <TableRow hover key={post.id} selected={isSelected}>
                    <TableCell>#{post.id}</TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <img
                          src={imageUrl || ""}
                          style={{ height: "50px", borderRadius: "5px" }}
                          alt={post.title}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {getPostTitle(post.name, post.isShared, post.shareText)}
                    </TableCell>
                    <TableCell>{`${post.profile.firstName} ${
                      post.profile.lastName || ""
                    }`}</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>{createdAt}</TableCell>

                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title={"View event list"}>
                          <Button
                            sx={{ minWidth: 24 }}
                            onClick={() =>
                              navigate(
                                GET_ADMIN_ROUTES.EVENT_POST(Number(post.id))
                              )
                            }
                            color="info"
                          >
                            <SvgIcon fontSize="small">
                              <BsEyeFill />
                            </SvgIcon>
                          </Button>
                        </Tooltip>
                        {/* <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setPostDeleteId(post.id)}
                          color="error"
                        >
                          <SvgIcon fontSize="small">
                            <BsTrash />
                          </SvgIcon>
                        </Button> */}
                        <Box
                          onClick={(e) => {
                            handleClick(e);
                            setMobId(post.id);
                          }}
                        >
                          <SvgIcon sx={{ cursor: "pointer" }}>
                            <SettingsOutlinedIcon />
                          </SvgIcon>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
      />

      {/* event edit drop down lst */}
      {mobId && (
        <CardMenuWrapper
          isEvent
          isAdminPanel
          id={mobId}
          userId={getUserId()}
          allModals={allModals}
          isCardMenuOpen={open}
          isMobCard
          isByBeingAdded={false}
          refetchMobs={() => {
            refetchEvents();
          }}
          toggleModals={(key, value) =>
            toggleModals((s) => ({ ...s, [key]: value }))
          }
          closeCardMenu={() => handleClose()}
          anchorEl={anchorEl}
          currentPrivacy={1}
          currentPermission={1}
          addedMembers={addedMembers}
          updateMemberList={(list) => setAddedMembers(list)}
        />
      )}
    </Card>
  );
};

EventsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  setEditId: PropTypes.func,
  refetchPost: PropTypes.func,
  setPostViewId: PropTypes.func,
  setPostDeleteId: PropTypes.func,
  refetchEvents: PropTypes.func,
};
