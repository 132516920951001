import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  mainDashboardContainer: {
    backgroundColor: theme.palette.background.default,
    minHeight: "90vh",
    paddingTop: 25,
  },
  dahsboard: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  info: {
    width: "100%",
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 32,
    textAlign: "center",
  },
}));

export default useStyles;
