import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import ReactModal from "../ReactModal";
import fetchMoreData, { checkHasMore } from "../../utils/fetchMore";
import PageLoader from "../PageLoader";
import MembersList from "../MemeberList";
import { Box } from "@mui/material";
import CustomButton from "../CutomButton";

const PER_PAGE = 20;

type MemberModalProps = {
  blogId: number;
  isOpen: boolean;
  handleClose: () => void;
};

export default function MemberModal({
  blogId,
  handleClose,
  isOpen,
}: MemberModalProps) {
  const {
    data: membersList,
    fetchMore,
    loading,
  } = useQuery(MEMBERS_LIST, {
    variables: { blogId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const getMemberInfo = () => {
    if (membersList?.latestMembersOnBlog.data) {
      return membersList?.latestMembersOnBlog.data.map((u) => ({
        avatarImage: u?.profile?.avatarImage || "",
        firstName: u?.profile?.firstName || "",
        lastName: u?.profile?.lastName || "",
        userId: u?.profile?.userId || 0,
        roleId: u?.roleId || 0,
        email: u?.profile?.email || "",
      }));
    }
    return [];
  };

  return (
    <ReactModal
      isOpen={isOpen}
      title="Event Members"
      handleClose={() => {
        handleClose();
      }}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <Box sx={{ mt: 4 }}>
          <MembersList
            members={getMemberInfo()}
            hasMore={checkHasMore(membersList, "latestMembersOnBlog")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: membersList,
                query: "latestMembersOnBlog",
                variables: {
                  blogId,
                },
                perPage: PER_PAGE,
                fetchMore,
              })
            }
            total={
              membersList?.latestMembersOnBlog.paginationDetails
                ?.totalRecords || 0
            }
            label="Member list"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 4,
            }}
          >
            <CustomButton label="Close" onClick={() => handleClose()} />
          </Box>
        </Box>
      )}
    </ReactModal>
  );
}

const MEMBERS_LIST = gql(`
    query LatestMembersOnEvent($blogId:Int!,$pagination:Pagination){
        latestMembersOnBlog(blogId:$blogId,pagination:$pagination){
            paginationDetails{
              currentPage
              lastPage
              totalRecords
            }
            data{
              userId
              blogId
              roleId
              createdAt
              roleInfo{
                id
                name
              }
              profile{
                userId
                firstName
                lastName
                gender
                dob
                avatarImage
                coverImage
                coverImageText
                email
                userName
                createdAt
                updatedAt
              }
            }
      }
    }`);
