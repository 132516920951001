import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

type EventSearchProps = {
  handleSubmit?: (name: string) => void;
  isEventSearch?: boolean;
};

export default function Search({
  handleSubmit,
  isEventSearch,
}: EventSearchProps): JSX.Element {
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  const [state, setstate] = useState<string>("");

  function submitSearch(): void {
    if (!!isEventSearch && handleSubmit) {
      handleSubmit(state);
    } else {
      navigate(`/posts/search/${state}`);
    }
  }

  return (
    <form
      className={s.form}
      onSubmit={(e) => {
        e.preventDefault();
        submitSearch();
      }}
    >
      <Box className={s.root}>
        <IconButton size="large" color="primary" className={s.iconButton}>
          <AiOutlineSearch />
        </IconButton>
        <input
          type="text"
          value={state}
          onChange={(e) => setstate(e.target.value)}
          placeholder={isEventSearch ? "Search in Events" : "Search in Moblog"}
          className={s.input}
        />
      </Box>
    </form>
  );
}
