import CardMenu from "./CardMenu";
import ModalWrapper from "./ModalWrapper";

type Props = {
  id: number;
  userId?: number;
  allModals: {
    editModal: boolean;
    editEventModal: boolean;
    memberModal: boolean;
    deleteModal: boolean;
    privacyModal: boolean;
    permissionModal: boolean;
    leaveModal: boolean;
    genarateLinkModal: boolean;
  };
  isCardMenuOpen: boolean;
  isMobCard: boolean;
  isByBeingAdded: boolean;
  isEvent?: boolean;
  isAdminPanel?: boolean;
  anchorEl: HTMLElement | null;
  currentPrivacy: number;
  currentPermission: number;
  addedMembers: [] | number[];
  closeCardMenu: () => void;
  toggleModals: (key: string, value: boolean) => void;
  refetchMobs: () => void;
  updateMemberList: (members: [] | number[]) => void;
};

export default function CardMenuWrapper({
  id,
  userId,
  allModals,
  isCardMenuOpen,
  isMobCard,
  isByBeingAdded,
  anchorEl,
  currentPrivacy,
  currentPermission,
  addedMembers,
  isEvent,
  isAdminPanel,
  closeCardMenu,
  toggleModals,
  refetchMobs,
  updateMemberList,
}: Props) {
  const handleOpenModals = (key: string) => {
    toggleModals(key, true);
    closeCardMenu();
  };
  return (
    <div>
      <CardMenu
        anchorEl={anchorEl}
        open={isCardMenuOpen}
        isMobCard={isMobCard}
        isByBeingAdded={isByBeingAdded}
        handleClose={closeCardMenu}
        openModal={(key: string) => handleOpenModals(key)}
        isEvent={isEvent}
      />

      {id && (
        <ModalWrapper
          id={id}
          userId={userId || null}
          isAdminPanel={isAdminPanel}
          allModals={allModals}
          memberList={addedMembers}
          currentPrivacy={currentPrivacy}
          currentPermission={currentPermission}
          toggleModals={toggleModals}
          refetchMobs={refetchMobs}
          setMemberCount={(m) => updateMemberList(m)}
        />
      )}
    </div>
  );
}
