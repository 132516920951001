import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { BsEyeFill, BsPencilSquare, BsTrash } from "react-icons/bs";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { FlagOutlined, FlagRounded } from "@mui/icons-material";
import { Scrollbar } from "../scrollbar";
import { getPostTitle } from "../../utils/postHelper";
import EventApproveButton from "../../components/EventCard/EventApproveButton";
import PostHighlightButton from "../../components/PostHighlightButton";

export const PostsTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    setEditId,
    refetchPost,
    setPostViewId,
    setPostDeleteId,
    isEventPosts,
  } = props;

  const [flagPost] = useMutation(FLAG_POST, {
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
      refetchPost();
    },
    onCompleted: () => refetchPost(),
  });

  const [removeFlaggedPost] = useMutation(REMOVE_FLAG_POST, {
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
      refetchPost();
    },
    onCompleted: () => refetchPost(),
  });

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell align="center">
                  {isEventPosts ? "Approve" : "Flag"}
                </TableCell>
                <TableCell align="center">Highlight</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((post: any) => {
                const isSelected = selected.includes(post.id);
                const createdAt = moment(post.createdAt).format(
                  "YYYY/MM/DD hh:mm A"
                );

                const imageUrl = !post.isShared
                  ? post.media?.[0]?.fileUrl
                  : post.originalPost?.media?.[0]?.fileUrl;

                return (
                  <TableRow hover key={post.id} selected={isSelected}>
                    <TableCell>#{post.id}</TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <img
                          src={imageUrl || ""}
                          style={{ height: "50px", borderRadius: "5px" }}
                          alt={post.title}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {getPostTitle(post.title, post.isShared, post.shareText)}
                    </TableCell>
                    <TableCell>{`${post.profile.firstName} ${
                      post.profile.lastName || ""
                    }`}</TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>{createdAt}</TableCell>

                    <TableCell sx={{ fontSize: "12px" }} align="center">
                      {isEventPosts ? (
                        <EventApproveButton
                          approved={!!post?.approved}
                          postId={post.id}
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            if (!post.flagged) {
                              flagPost({ variables: { postId: post.id } });
                            } else {
                              removeFlaggedPost({
                                variables: { postId: post.id },
                              });
                            }
                          }}
                          color="info"
                        >
                          <SvgIcon>
                            {post.flagged ? <FlagRounded /> : <FlagOutlined />}
                            <FlagOutlined />
                          </SvgIcon>
                        </Button>
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }} align="center">
                      <PostHighlightButton
                        eventId={post.blogId}
                        postId={post.id}
                        highlighted={!!post.highlighted?.postId}
                        isEventCard={!!isEventPosts}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <Box
                        sx={{ display: "flex", justifyContent: "space-around" }}
                      >
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => {
                            if (!post.isShared) {
                              setEditId(post.id);
                            }
                          }}
                          disabled={post.isShared}
                        >
                          <SvgIcon fontSize="small">
                            <BsPencilSquare />
                          </SvgIcon>
                        </Button>
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setPostViewId(post.id)}
                          color="info"
                        >
                          <SvgIcon fontSize="small">
                            <BsEyeFill />
                          </SvgIcon>
                        </Button>
                        <Button
                          sx={{ mr: 1, minWidth: 24 }}
                          onClick={() => setPostDeleteId(post.id)}
                          color="error"
                        >
                          <SvgIcon fontSize="small">
                            <BsTrash />
                          </SvgIcon>
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Card>
  );
};

PostsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  setEditId: PropTypes.func,
  refetchPost: PropTypes.func,
  setPostViewId: PropTypes.func,
  setPostDeleteId: PropTypes.func,
  isEventPosts: PropTypes.bool,
};

const FLAG_POST = gql(`mutation FlagPost($postId:Int!){
  flagPost(postId:$postId){
    message
  }
}`);

const REMOVE_FLAG_POST = gql(`mutation RemoveFlaggedPost($postId:Int!){
  removeFlaggedPost(postId:$postId){
    message
  }
}`);
