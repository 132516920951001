import { Box } from "@mui/material";
import CustomButton from "../CutomButton";

type Props = {
  fetchData: () => void;
};

const LoadMore = ({ fetchData }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        my: 6,
      }}
    >
      <CustomButton
        sx={{ width: "50%" }}
        label="Load more..."
        onClick={() => fetchData()}
      />
    </Box>
  );
};

export default LoadMore;
