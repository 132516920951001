import React, { useContext, useEffect, useState } from "react";
import FormOne from "./Forms/FormOne";
import FormTwo from "./Forms/FormTwo";
import useStyles from "./styles";
import BackgroundScreen from "../../components/BackGroundScreen";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import formatDate from "../../utils/formatDate";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { THEMES_LIST } from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../../Context";
import ROUTES from "../../utils/routes";

type UserMoreDetails = {
  firstName: string;
  lastName: string;
  dob: Date | null;
  blogName: string;
  theme: number;
  image: File | null;
  blogImage: File | null;
};

export default function SignUpMoreDetails(): JSX.Element {
  const { classes: s } = useStyles();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventUuid = searchParams.get("eventUuid");
  const [currentForm, setCurrentForm] = useState(0);
  const [userMoreDetails, setUserMoreDetails] = useState<UserMoreDetails>({
    firstName: "",
    lastName: "",
    dob: null,
    blogName: "",
    image: null,
    theme: THEMES_LIST.WALLPAPER,
    blogImage: null,
  });

  const { data: getEventData } = useQuery(GET_EVENT_ID, {
    variables: { eventUuid: eventUuid || "" },
    skip: !eventUuid,
  });

  const [joinToEvent, { loading: isJoining }] = useMutation(JOIN_TO_EVENT, {
    onCompleted: (data) => {
      window.location.replace(
        ROUTES.EVENT_VIEW_ID + data.joinToBlogUsingLink.blogId
      );
    },
    onError: (err) => {
      const errMsg = graphqlErrorToMsg(err);
      const blogId = getEventData?.blogIdByEventUuid.blogId;
      if (errMsg === "Already member of given blog" && blogId) {
        window.location.replace(ROUTES.EVENT_VIEW_ID + blogId);
      } else {
        window.location.replace(ROUTES.DASHBOARD);
      }
    },
  });

  const [completeProfile, { loading, error }] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {
      if (eventUuid) {
        joinToEvent();
      } else {
        window.location.replace("/dashboard");
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token && user?.isCompleted) navigate(ROUTES.DASHBOARD);
    if (!token) navigate("/");
  }, [navigate, user?.isCompleted]);

  const forms = [
    <FormOne
      next={() => setCurrentForm(1)}
      userMoreDetails={userMoreDetails}
      setUserMoreDetails={setUserMoreDetails}
    />,
    <FormTwo
      loading={loading || isJoining}
      userMoreDetails={userMoreDetails}
      error={error ? graphqlErrorToMsg(error) : null}
      back={(formData) => {
        setUserMoreDetails((s) => ({ ...s, ...formData }));
        setCurrentForm(0);
      }}
      submitData={(formData) => {
        setUserMoreDetails((s) => ({ ...s, ...formData }));
        completeProfile({
          variables: {
            info: {
              blogAbout: "",
              dob: userMoreDetails.dob ? formatDate(userMoreDetails.dob) : null,
              firstName: userMoreDetails.firstName,
              lastName: userMoreDetails.lastName,
              blogName: formData.blogName,
              theme: formData.theme,
            },
            avatarImage: userMoreDetails.image,
            blogImage: formData.blogImage,
          },
        });
      }}
    />,
  ];

  return (
    <BackgroundScreen>
      <div className={s.mainContainer}> {forms[currentForm]}</div>
    </BackgroundScreen>
  );
}

const UPDATE_PROFILE = gql(`
mutation completeProfile($info: CompleteProfileInput!, $avatarImage: Upload,$blogImage:Upload) {
  completeProfile(info: $info, avatarImage: $avatarImage,blogImage:$blogImage) {
    email
    userId
  }
}
`);

const JOIN_TO_EVENT = gql(`mutation JoinToBlogUsingLink($uuid:String!){
  joinToBlogUsingLink(uuid:$uuid){
    blogId
  }
}`);

const GET_EVENT_ID = gql(`query blogIdByEventUuid($eventUuid:String!){
  blogIdByEventUuid(eventUuid:$eventUuid){
    blogId
  }
}`);
