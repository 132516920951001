import { useState } from "react";
import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import HeaderSection from "../../components/EventsPageComponent/headerSection";
import ReactModal from "../../components/ReactModal";
import MessageBox from "../../components/MessageBox";
import EventPhotoUploadWrapper from "../../components/EventsComponents/EventPhotoUploadWrapper";
import EventCard from "../../components/EventCard/EventCard";
import MasonryContainer from "../../containers/MasonryContainer";

export default function EventViewPage() {
  const params = useParams();
  const eventId = Number(params.id) || 0;

  const [showModal, setModal] = useState(false);

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery(EVENT_IMAGES_LIST, {
    fetchPolicy: "network-only",
    variables: { blogId: eventId, pagination: { nextPage: 1, perPage: 20 } },
  });

  if (!eventId) {
    return (
      <Container maxWidth="xl" sx={{ my: 3 }}>
        Event not found
      </Container>
    );
  }
  return (
    <Container maxWidth="xl" sx={{ my: 3, color: `rgb(2, 48, 71)` }}>
      <HeaderSection eventId={eventId} handleUpload={() => setModal(true)} />

      {/* upload images */}
      {showModal && (
        <ReactModal
          handleClose={() => setModal(false)}
          isOpen={showModal}
          modelWidth={{ xs: "90%", md: "80%", lg: "60%" }}
        >
          <EventPhotoUploadWrapper
            blogId={eventId}
            onComplete={() => {
              setModal(false);
              refetch();
            }}
            isEventPage
            handleCancel={() => setModal(false)}
          />
        </ReactModal>
      )}

      {/* Main area */}
      {data?.mobPostsByBlogId?.data && (
        <Box sx={{ my: 4 }}>
          <MasonryContainer loading={isLoading}>
            {data.mobPostsByBlogId?.data.map((item) => {
              if (!item) return null;

              const imageUrl = item?.isShared
                ? item?.originalPost?.media?.[0]?.fileUrl
                : item?.media?.[0]?.fileUrl;
              const title = item?.isShared ? item.shareText : item?.title;

              return (
                <EventCard
                  key={item?.id}
                  eventId={eventId}
                  postId={item.id}
                  imageUrl={imageUrl || ""}
                  title={title || ""}
                  highlighted={!!item.highlighted?.postId}
                  approved={!!item.approved}
                  isEventImage
                />
              );
            })}
          </MasonryContainer>
        </Box>
      )}
      {data?.mobPostsByBlogId?.data?.length === 0 && (
        <MessageBox message="No images to show" />
      )}
    </Container>
  );
}

const EVENT_IMAGES_LIST =
  gql(`query EventImageById($blogId:Int!,$pagination:Pagination){
    mobPostsByBlogId(blogId:$blogId,pagination:$pagination){
       paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        id
        userId
        blogId
        title
        description
        privacyLevel
        hidden
        allowReshare
        isShared
        approved
        highlighted{
          postId
        }
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        media{
          id
          postId
          fileUrl
          s3Key
        }
        createdAt
        updatedAt
      }
      
    }
  }`);
