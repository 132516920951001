import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import BackgroundScreen from "../../components/BackGroundScreen";
import CustomInput from "../../components/CustomInput";
import useStyles from "./styles";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CustomModal from "../../components/CustomModal";
import ROUTES from "../../utils/routes";
import { passwordValidation } from "../../utils/validations";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  userName: Yup.string().required().label("User Name"),
  password: passwordValidation("Password"),
  termsAndCondition: Yup.boolean()
    .oneOf([true], "Field must be checked")
    .required()
    .label("Terms and conditions"),
  privacyPolicy: Yup.boolean()
    .oneOf([true], "Field must be checked")
    .required()
    .label("Privacy Policy"),
});

export default function SignUpPage(): JSX.Element {
  const [showModal, setModal] = useState(false);
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventUuid = searchParams.get("eventUuid");

  const [signUp, { loading, error }] = useMutation(SIGN_UP, {
    onCompleted: () => {
      setModal(true);
    },
  });

  const goToLoginPage = () => {
    const pathname = ROUTES.LOGINPAGE;
    const search = `?eventUuid=${encodeURIComponent(`${eventUuid}`)}`;
    if (!eventUuid) {
      navigate(pathname);
    } else {
      navigate(pathname + search);
    }
  };

  function registerUser(values: any): void {
    const info = {
      email: values.email,
      userName: values.userName,
      password: values.password,
      termsAndConditionId: values.termsAndCondition ? 1 : null,
      privacyPolicyId: values.privacyPolicy ? 2 : null,
      eventUuid: eventUuid || null,
    };
    signUp({
      variables: {
        info,
      },
    });
  }
  return (
    <BackgroundScreen>
      <div className={s.formContainer}>
        <div className={s.formWrapper}>
          <p className={s.title}>Moblog</p>
          <div>
            <Formik
              initialValues={{
                email: "",
                userName: "",
                password: "",
                termsAndCondition: false,
                privacyPolicy: false,
              }}
              onSubmit={(values) => {
                registerUser(values);
              }}
              validationSchema={validationSchema}
            >
              {(formikProps) => {
                const {
                  values,
                  errors,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                } = formikProps;
                return (
                  <Form autoComplete="new-password">
                    <CustomInput
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors?.email}
                      touched={touched.email}
                    />

                    <CustomInput
                      name="userName"
                      value={values.userName}
                      placeholder="User name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors?.userName}
                      touched={touched.userName}
                    />

                    <CustomInput
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputType="password"
                      error={errors?.password}
                      touched={touched.password}
                    />

                    <div className={s.termsContainer}>
                      <Typography sx={{ fontSize: "12px", py: 1 }}>
                        Please review our{" "}
                        <Link to={ROUTES.TERMS_OF_SERVICES}>
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy.</Link>{" "}
                        Feel free to{" "}
                        <Link to={ROUTES.CONTACT_US}>contact us</Link> if you
                        have any questions or concerns.
                      </Typography>
                    </div>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="termsAndCondition"
                            value={values.termsAndCondition}
                            size="small"
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: 12,
                              color:
                                errors.termsAndCondition &&
                                touched.termsAndCondition
                                  ? "#ff0000"
                                  : "initial",
                            }}
                          >
                            {"I agree to the Terms of Service"}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="privacyPolicy"
                            value={values.privacyPolicy}
                            size="small"
                            onChange={handleChange}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: 12,
                              color:
                                errors.privacyPolicy && touched.privacyPolicy
                                  ? "#ff0000"
                                  : "initial",
                            }}
                          >
                            {"I agree to the Privacy Policy"}
                          </Typography>
                        }
                      />
                    </Box>
                    {error && (
                      <div className={s.errorMsg}>
                        {graphqlErrorToMsg(error)}
                      </div>
                    )}

                    <Button
                      className={s.loginBtn}
                      variant="contained"
                      onClick={() => {
                        if (!loading) {
                          handleSubmit();
                        }
                      }}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "white" }} size={25} />
                      ) : (
                        "Sign up"
                      )}
                    </Button>
                    <div>
                      <Divider>or</Divider>
                    </div>
                    <div>
                      <Box
                        className={s.link}
                        onClick={() => {
                          goToLoginPage();
                        }}
                      >
                        Sign in
                      </Box>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={showModal}
        handleClose={() => navigate("/")}
        handleSubmit={() => navigate("/")}
        buttonLabel="Done"
        modelWidth={400}
        buttonJustify={"center"}
      >
        <Box sx={{ textAlign: "center" }}>
          <h3>Thanks For Registering!</h3>
          <p>
            To complete your registration, please check the email you entered
            and follow the instructions.
          </p>
        </Box>
      </CustomModal>
    </BackgroundScreen>
  );
}

const SIGN_UP = gql`
  mutation signUp($info: SignUpInput!) {
    signUp(info: $info) {
      message
    }
  }
`;
