import { useState } from "react";
import SendOTPEmail from "../../components/OTP/SendOTPEmail";
import LoginWithOtp from "../../components/OTP/LoginWithOtp";

type OtpWrapperProps = {
  eventId: number;
};

const OtpWrapper = ({ eventId }: OtpWrapperProps) => {
  const [showSendOtp, setSendOtp] = useState(false);
  return (
    <>
      {!showSendOtp && (
        <SendOTPEmail
          eventId={eventId}
          handleComplete={() => setSendOtp(true)}
        />
      )}
      {showSendOtp && <LoginWithOtp handleComplete={() => {}} />}
    </>
  );
};

export default OtpWrapper;
