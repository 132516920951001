import Masonry from "react-masonry-css";
import { ReactNode } from "react";

import useStyles from "./styles";
import PageLoader from "../../components/PageLoader";

interface Props {
  children: ReactNode;
  loading?: boolean;
}

export default function MasonryContainer(props: Props): JSX.Element {
  const { classes: s } = useStyles();
  const { loading, children } = props;

  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    900: 2,
    500: 1,
  };

  if (loading) return <PageLoader />;

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName={`my-masonry-grid_column ${s.gridColumn}`}
    >
      {children}
    </Masonry>
  );
}
