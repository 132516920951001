import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  const { secondary, mode, text, background } = theme.palette;
  const bgColor = mode === "light" ? "#8ecae62f" : "#fff";
  // const color = mode === 'light' ? primary.main : text.secondary;

  const fontColor = text.primary;

  return {
    bottomIcons: {
      color: text.primary,
      marginTop: 5,
      marginLeft: 5,
      cursor: "pointer",
    },
    commentContainer: {
      display: "flex",
      flexDirection: "row",
    },
    commentCount: {
      fontSize: 18,
      cursor: "pointer",
      color: text.primary,
      "&:hover": {
        color: "purple",
      },
    },
    createdDateText: {
      margin: 0,
      fontSize: 12,
      color: theme.palette.text.primary,
    },
    errorSharing: {
      fontSize: 13,
      color: "red",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
    },
    iconWrapper: {
      paddingLeft: 12,
      display: "flex",
      alignItems: "center",
      gap: "3px",
    },
    postCard: {
      padding: 10,
    },
    postCardContainer: {
      width: "100%",
      borderRadius: 10,
      height: "auto",
      marginBottom: 20,
      backgroundColor: bgColor,
    },
    highlightContainer: {
      width: "100%",
      borderRadius: 15,
      height: "auto",
      marginBottom: 20,
      backgroundColor: bgColor,
      border:
        mode === "dark"
          ? `4px solid ${text.primary}`
          : `4px solid ${secondary.main}`,
    },
    postDescription: {
      margin: 0,
      fontSize: 14,
      color: fontColor,
    },
    postCardFollowButton: {
      marginTop: 0,
      fontSize: theme.typography.h4.fontSize,
      marginLeft: -5,
    },
    postCardImageContainer: {
      width: "100%",
      height: "auto",
      borderRadius: 10,
      cursor: "pointer",
      position: "relative",
    },
    image: {
      width: "100%",
      maxWidth: "100%",
      height: "auto",
      borderRadius: 10,
      verticalAlign: "middle",
    },
    loadingImage: {
      width: "100%",
      maxWidth: "100%",
      height: 300,
      borderRadius: 10,
      verticalAlign: "middle",
    },
    postCardOptionsDropDown: {
      marginTop: 10,
      color: text.primary,
    },
    postCardTagName: {
      fontSize: 20,
      color: text.primary,
      "&:hover": {
        color: "purple",
      },
      marginBottom: 0,
    },
    postTitle: {
      fontSize: "18px",
      margin: 0,
      fontWeight: 600,
      color: fontColor,
    },
    eventtitle: {
      fontSize: "18px",
      margin: 0,
      fontWeight: 600,
      color: "white",
    },
    postCardUserName: {
      margin: 0,
      color: "#219EBC",
    },
    seeMoreButton: {
      marginLeft: -10,
      color: "#219EBC",
    },
    shareContainer: {
      backgroundColor: background.paper,
      color: text.primary,
    },
    shareButton: {
      marginTop: 10,
    },
    shareInput: {
      backgroundColor: background.default,
    },
    shareSuccessfullMessage: {
      fontSize: 13,
      color: "green",
    },

    titleAndDesContainer: {
      padding: 10,
    },
    upperRowContainer: {
      paddingTop: 10,
      paddingLeft: 20,
      paddingBottom: 10,
      paddingRight: 20,
    },
    imageWrapper: {
      position: "relative",
    },
    overlay: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        mode === "dark" ? "rgb(0 22 33 / 73%)" : "rgb(123 200 219 / 70%);",
      top: 0,
      left: 0,
      borderRadius: 10,
      padding: 10,
      justifyContent: "flex-end",
    },

    eventOverlay: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      width: "100%",
      height: "50px",
      background: `rgba(2, 48, 71, 0.5)`,
      top: 0,
      left: 0,
      borderRadius: "10px 10px 0 0",
      padding: 10,
      justifyContent: "flex-end",
    },

    actionButtons: {
      padding: 4,
      minWidth: "auto",
      opacity: 0.65,
      color: "white",
      borderRadius: 4,
    },
    actionActiveButtons: {
      padding: 4,
      minWidth: "auto",
      color: "white",
      borderRadius: 4,
    },
    title: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});

export default useStyles;
