export const ADMIN_PATH = "/admin/dashboard";

const ADMIN_ROUTES = {
  USERS: `${ADMIN_PATH}/users`,
  MOBS: `${ADMIN_PATH}/mobs`,
  MOB_POSTS: `${ADMIN_PATH}/mobs/posts/:blogId`,
  POSTS: `${ADMIN_PATH}/posts`,
  PROFILE: `${ADMIN_PATH}/profile`,
  EVENTS: `${ADMIN_PATH}/events`,
  EVENT_POSTS: `${ADMIN_PATH}/events/posts/:eventId`,
};

export const GET_ADMIN_ROUTES = {
  EVENT_POST: (id: number) => `${ADMIN_PATH}/events/posts/${id}`,
};

export default ADMIN_ROUTES;
