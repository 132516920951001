import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import HooksInput from "../Input/Input";
import DatePickerInput from "../DatePickerInput/DatePickerInput";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { USER_PRIVACY_LEVELS, USER_ROLES } from "../../utils/constants";
import { UserRole } from "../../types/userTypes";
import moment from "moment";

type userData = {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  dob?: string | null;
  roles: UserRole[];
};

type ProfileDetailsProps = {
  handleClose?: () => void;
  userDetails: userData;
  refetch: () => void;
  isProfilePage?: boolean;
};

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required().label("First Name").min(4).max(255),
    lastName: yup.string().required().label("Last Name").min(4).max(255),
    email: yup.string().required().label("Email"),
    userName: yup.string().required().label("Username"),
    admin: yup.boolean(),
    moderator: yup.boolean(),
    curator: yup.boolean(),
    super_admin: yup.boolean(),
    user: yup.boolean(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export const ProfileDetails = ({
  handleClose,
  userDetails,
  refetch,
  isProfilePage,
}: ProfileDetailsProps) => {
  const checkUserHasRole = (role: number) => {
    return !!userDetails.roles.find((r) => r.roleId === role);
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      userName: userDetails.userName,
      dob: userDetails.dob,
      user: checkUserHasRole(USER_ROLES.USER),
      moderator: checkUserHasRole(USER_ROLES.MODERATOR),
      admin: checkUserHasRole(USER_ROLES.ADMIN),
      curator: checkUserHasRole(USER_ROLES.CURATOR),
      super_admin: false,
    },
  });

  const [updateUserByAdmin, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      toast.success(` User updated successfully`);
      refetch();
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const [updateProfile, { loading: isLoading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {
      toast.success(`Profile updated successfully`);
      refetch();
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  const updateUser = (values: any) => {
    const userRoles = [];

    if (values.moderator) {
      userRoles.push(USER_ROLES.MODERATOR);
    }
    if (values.admin) {
      userRoles.push(USER_ROLES.ADMIN);
    }
    if (values.super_admin) {
      userRoles.push(USER_ROLES.ADMIN);
    }
    if (values.user) {
      userRoles.push(USER_ROLES.USER);
    }
    if (values.curator) {
      userRoles.push(USER_ROLES.CURATOR);
    }

    updateUserByAdmin({
      variables: {
        info: {
          dob: values.dob ? moment(values.dob).format("YYYY-MM-DD") : null,
          firstName: values.firstName,
          lastName: values.lastName,
          userId: userDetails.userId,
          mentionable: false,
          privacyLevel: USER_PRIVACY_LEVELS.PUBLIC,
          roles: userRoles,
          taggable: true,
        },
      },
    });
  };

  const updateUserProfile = (values: any) => {
    updateProfile({
      variables: {
        info: {
          dob: values.dob ? moment(values.dob).format("YYYY-MM-DD") : null,
          firstName: values.firstName,
          lastName: values.lastName,
          mentionable: true,
          taggable: true,
        },
        image: null,
      },
    });
  };

  const isUser = watch("user");
  const isAdmin = watch("admin");
  const isModerator = watch("moderator");
  const isCurator = watch("curator");

  return (
    <form
      onSubmit={handleSubmit(isProfilePage ? updateUserProfile : updateUser)}
    >
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <HooksInput
                  name="firstName"
                  label="First Name"
                  required
                  register={register}
                  error={errors.firstName?.message}
                  touched={touchedFields.firstName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <HooksInput
                  name="lastName"
                  label="Last Name"
                  register={register}
                  required
                  error={errors.lastName?.message}
                  touched={touchedFields.lastName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <HooksInput
                  inputType="email"
                  name="email"
                  label="Email Address"
                  register={register}
                  required
                  error={errors.email?.message}
                  touched={touchedFields.email}
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <HooksInput
                  name="userName"
                  label="Username"
                  register={register}
                  required
                  error={errors.userName?.message}
                  touched={touchedFields.userName}
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <DatePickerInput
                  control={control}
                  name="dob"
                  label="Date of Birth"
                />
              </Grid>
              <Grid xs={12}>
                <FormControlLabel
                  checked={isUser}
                  control={<Checkbox {...register("user")} />}
                  label="User"
                />
                <FormControlLabel
                  checked={isCurator}
                  control={<Checkbox {...register("curator")} />}
                  label="Curator"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("admin")}
                      checked={isAdmin}
                      onChange={(e) => {
                        if (!isAdmin) {
                          setValue("admin", true);
                          setValue("moderator", true);
                        } else {
                          setValue("admin", false);
                        }
                      }}
                    />
                  }
                  label="Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("moderator")}
                      disabled={isAdmin}
                      checked={isAdmin || isModerator}
                    />
                  }
                  label="Moderator"
                />
              </Grid>
              {/* {!isProfilePage && (
                <Grid xs={12}>
                  {USER_ROLES_LIST.map((r) => (
                    <CheckBoxInput
                      key={r.id}
                      control={control}
                      label={r.label}
                      name={r.name}
                      defaultChecked={checkUserHasRole(r.id)}
                    />
                  ))}
                </Grid>
              )} */}
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end", my: 2, px: 4 }}>
          {
            <Button variant="contained" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
          }
          {loading || isLoading ? (
            <Button variant="contained" sx={{ px: 6 }}>
              <CircularProgress size={25} />
            </Button>
          ) : (
            <Button variant="contained" type="submit">
              Save
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};

const UPDATE_USER =
  gql(`mutation ProfileEditAdmin($info:ProfileEditAdminInput!){
  profileEditAdmin(info:$info){
    userId
    email
    userName
    profile{
      userId
      firstName
      lastName
      dob
      avatarImage
    }
  }
}`);

const UPDATE_PROFILE =
  gql(`mutation SelfProfileEdit($info: SelfProfileEditInput!,$image:Upload) {
  selfProfileEdit(info: $info,image:$image) {
    email
    userId
    profile {
      userName
      avatarImage
      createdAt
      dob
      firstName
      lastName
      updatedAt
      userId
    }
  }
}`);
