import { Box } from "@mui/material";
import useStyles from "./EventCardActionsStyles";

import EventApproveButton from "./EventApproveButton";
import PostHighlightButton from "../PostHighlightButton";

type EventCardActionProps = {
  eventId: number;
  postId: number;
  highlighted: boolean;
  approved: boolean;
};

const EventCardAction = ({
  eventId,
  postId,
  approved,
  highlighted,
}: EventCardActionProps) => {
  const { classes } = useStyles();

  return (
    <Box>
      <div
        style={{
          justifyContent: "flex-start",
          zIndex: 10,
        }}
        className={classes.actionButtonsWrapper}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2 }}>
          <EventApproveButton approved={approved} postId={postId} />
          <PostHighlightButton
            isEventCard
            highlighted={highlighted}
            postId={postId}
            eventId={eventId}
          />
        </Box>
      </div>
    </Box>
  );
};

export default EventCardAction;
