const ROUTES = {
  DASHBOARD: "/dashboard",
  LOGINPAGE: "/",
  CONTACT_US: "/contact-us",
  TERMS_OF_SERVICES: "/terms-of-services",
  PRIVACY_POLICY: "/privacy-policy",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/account/password/reset/:token",
  SETTINGS: "/settings",
  CREATE_POST: "/create-post",
  PROFILE: "/user/:userId",
  PROFILE_PAGE: "/user",
  POST_VIEW: "/post/:postID",
  POST_VIEW_PAGE: "/post",
  COMPLETE_SIGN_IN: "/signup/user",
  SIGN_UP: "/signup",
  ACCOUNT_VERIFY: "/account/verification/:uuid",
  ADMIN_DASHBOARD: "/admin/dashboard",
  MOBS: "/moblog/:id",
  MOBS_PAGE: "/moblog",
  MOBS_JOINGING: "/moblog/join",
  MOBS_JOINGING_PAGE: "/moblog/join/:uuid",
  IMAGE_VIEW: "/image/:postID",
  IMAGE_VIEW_ID: "/image/",

  CREATE_EVENT: "/create-event",
  EVENTS: "/events",
  EVENT_VIEW: "/event/:id",
  EVENT_VIEW_ID: "/event/",
  EVENT_JOINING_PAGE: "/join/:eventUuid",
  EVENT_DOWNLOAD_PAGE: "/event-download/:eventId",
  EVENT_DOWNLOAD_PAGE_WITH_ID: "/event-download/",
};

export default ROUTES;
